<template>
  <div class="container">
      <div class="user-info">
        <div class="img-head">
            <img :src="userinfoCareer.us_headimgurl" class="logoStyle" alt="">
        </div>
        <div class="user-name">{{userinfoCareer.us_name}}</div>
      </div>
    <div class="two-buttom">
       <div :class="[chooseItem === 1 ? 'pick':'unpick']" @click="searchAllReport">
           全部报告
       </div>
       <div :class="[chooseItem === 1 ? 'unpick':'pick']" @click="searchNopayReport">
           未支付报告
       </div>
    </div>
    <div class="addButtom">
         <el-button  v-if="!this.userinfoCareer.us_openid"  class="buttonColor" icon="el-icon-plus" round @click="gotohome">重新登录</el-button>
         <el-button  v-else class="buttonColor" icon="el-icon-plus" round @click="gotoAdd">添加报告</el-button>
    </div>
    <div class="reportList">
      <div v-if="reportList.length > 0">
          <div v-for="(item,index) in reportList" :key="index" class="item-card">
            <div class="wordButton">
                 <div class="name">
                    <div>{{item.re_name}}&nbsp;
                      <i v-if="item.re_sex == 1" class="iconfont icon-nansheng1">&nbsp;</i>
                      <i v-else class="iconfont icon-nvsheng1">&nbsp;</i>
                    </div>
                    <div style="margin-top:8px">{{changeTime(item.re_birthday)}}</div>
                 </div>
                 <div class="button" @click="gotoDetail(item.re_pay,item.re_id)">
                    <div>
                        <img src="../assets/view.png" class="buttonStyle" alt="">
                    </div>
                 </div>
            </div>
            <div v-if="item.re_pay" class="pay"><img src="../assets/pay.png" style="width:60px;height:30px"  alt=""></div>
         </div>
         <div style="text-align:right;padding: 6px 16px;text-decoration:underline">
           <span style="color:white;font-size:14px">若在旧系统购买过报告，请点击</span><el-button type="text" style="color:rgb(45,218,180);font-size:14px;" @click="gotobind">绑定账号</el-button>
         </div>
      </div>
      <div class="warning" v-else>
          <!-- <p> 首次登陆，若无头像，请点击<el-button type="text" style="color:rgb(45,218,180);font-size:16px;" @click="gotohome">重新登录</el-button></p> -->
          <p> 暂无报告，请点击<el-button type="text" style="color:rgb(45,218,180);font-size:16px;" @click="gotoAdd">添加报告</el-button></p>
           <!-- 或<el-button type="text" style="color:rgb(45,218,180);font-size:16px;" @click="gotobind">绑定旧系统账号</el-button> -->
      </div>
      <div style="text-align:center;color:#fff;margin-top:20px">
            <img src="../assets/kefu.jpg" style="width:30%"  alt="">
             <div style="font-size:14px;margin:20px">系统问题反馈请长按二维码，添加微信。</div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import { areaList } from '@vant/area-data';
import { Toast } from 'vant';
import {postAction} from '@/api/manage'
import {mapState} from "vuex"
export default {
  name: 'success',
  data() {
    return {
      userName:'喵星君',
      chooseItem:1,
      // reportList:[],
      reportList:[
      ],
      reportListAll:[
      ],
      userInfo:{
      }
    }
  },
  mounted () {
   if(!this.userinfoCareer.us_openid){
     this.getOpenID()
   }else {
      this.getRepotList(3)
   }
  },
  computed:{
    userinfoCareer() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.$store.getters.getUserinfoCareer || {};
    },
    code(){
      return this.$route.query.code ? this.$route.query.code : ''
    },
  },
  methods: {
    changeTime(time){
      if(time){
       const timeList = time.split('-')
       return `${timeList[0]}年${timeList[1]}月${timeList[2]}日${timeList[3]}时`
      } else{
        return ''
      }
    },
    gotoAdd(){
      this.$router.push({path:'/addReport'})
    },
    gotohome(){
      this.$router.push({path:'/home'})
    },
    gotobind(){
      this.$router.push({path:'/bindUser'})
    },
    gotoDetail(val,id){
      if(val === 1){
       this.$router.push({path:'/careerResult', query:{re_id:id}})
      }else{
        const loading = this.$loading({
          lock: true,
          text: '正在评定中，请稍后...',
          background: 'rgba(11, 1, 70, 0.9)'
        });
        setTimeout(() => {
          loading.close();
          this.$router.push({path:'/careerResultNopay', query:{re_id:id}})
        }, 3000);
      }
    },
    getOpenID(){
      const url = '/pr/vxlogin'
      // const url = '/pr/test'
          postAction(url, {code:this.code}).then( res => {
            this.userInfo = res.data.data
            this.$store.commit('setUserInfoCareer', this.userInfo)
            // this.$store.dispatch("setuidval", this.userInfo.us_id);
            // this.$store.dispatch("setopenidval", this.userInfo.us_openid);
            // this.$store.dispatch("setunionidval", this.userInfo.us_unionid);
            // this.$store.dispatch("setheadimgval", this.userInfo.us_headimgurl);
            // this.$store.dispatch("setnameval", this.userInfo.us_name);
            this.getRepotList(3)
          })
    },
    getRepotList(pay){
      const url = '/pr/list_report'
      postAction(url, {pay:pay,openid:this.userinfoCareer.us_openid}).then( res => {
        this.reportListAll = res.data.data
        this.reportList = [...this.reportListAll]
      })
    },
    searchAllReport(){
      this.chooseItem = 1
      this.reportList = [...this.reportListAll]
    },
    searchNopayReport(){
      this.chooseItem = 2
      this.reportList = this.reportListAll.filter(item => item.re_pay == 0)

    }
  }
}
</script>
<style scoped>
.container{
  background-image: url('../assets/reportBg.jpg');
  background-size: 99% 100%;
  width: 100%;
  min-height: 98vh;
  height: 100%;
  padding-bottom: 20px;
}
.user-info{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
}
.warning{
  color: #fff;
  margin-top: 100px;
}
.img-head{
  width: 80px;
  height: 80px;
  border: 2px solid white;
  border-radius: 40px;
}
.logoStyle{
  width: 80px;
  height: 80px;
  border-radius: 40px;
}
.user-name{
    color: white;
    margin-top: 16px
}
.two-buttom{
    display: flex;
    width: 100%;
    margin-top: 16px;
}
.pick{
    background-image: url('../assets/button1.png');
    background-size: 99% 40px;
    background-repeat: no-repeat;
    height: 40px;
    line-height: 40px;
    text-align: center;
    width: 50%;
    color: white;
}
.unpick{
    background-image: url('../assets/button2.png');
    background-size: 99% 40px;
    background-repeat: no-repeat;
    height: 40px;
    line-height: 40px;
    width: 50%;
    text-align: center;
    color:rgb(39,85,147)
}
.buttonColor{
  background: #2DDA82;
  color: white;
}
.addButtom{
  display: flex;
  margin-left: 10px;
  margin-top: 16px;
}
.reportList{
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  padding: 0px 10px;
}
.item-card{
    width: 100%;
    height: 70px;
    background-image: url('../assets/listcard.png');
    background-size: 99% 70px;
    background-repeat: no-repeat;
    border-radius: 6px;
    margin-top: 10px;
    position: relative;
}
.wordButton{
    display: flex;
    justify-content: space-between;
}
.name{
    display: flex;
    flex-direction: column;
    color: white;
    text-align: left;
    padding: 10px 40px;
}
.button{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
    cursor: pointer;
}
.pay{
    position: absolute;
    left: 0px;
    bottom: -2px;
}
.buttonStyle{
  width: 70px;
  height: 30px;
}
/deep/ .el-button{
    border: 0px solid white
}
</style>


