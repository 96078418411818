<template>
  <div class="container">
      <div class="color-gray66 pt20 pl30 tl fs20 fwbold">匹配记录</div>
       <div v-for="(item,index) in recordList" :key="index" class="listBox tl row-start-col-center">
           <div @click="gotoResult(item.id)"><span class="fs20 fwbold color-gray66">{{index + 1}}.</span>
              <span class="ml10 fs16 color-gray66">{{item.name}}-{{item.birthday}}-{{item.sex}}</span>
           </div>
           <i @click="deleteReport(item.id)" class="el-icon-delete ml20"></i>
       </div>
  </div>
</template>
<script>
import axios from 'axios'
import { areaList } from '@vant/area-data';
import { Toast } from 'vant';
import { Dialog } from 'vant';
import {postAction} from '@/api/manage'
export default {
  name: 'success',
  data() {
    return {
     recordList:1,
   };
  },
  computed:{
    userinfoLove() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.$store.getters.getUserinfoLove || {};
    },
    openid() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.userinfoLove.jsopenid;
    },
    unionid() {
      // 通过vuex的getters方法来获取state里面的数据
        return this.userinfoLove.unionid;
    },
    headimg() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.userinfoLove.image;
    },
    name() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.userinfoLove.nickname;
    },
  },
  mounted(){
    this.getList()
  },
  methods:{
    gotoResult(id){
      this.$router.push({path:'/reliableResult',query:{id:id}})
    },
    getList(){
        const url = '/xcxlove/double/list'
        postAction(url, {unionid:this.unionid}).then(res => {
          if (res.data.code == 200) {
              this.recordList = res.data.msg
              this.recordList.forEach(item => {
                 const birthdaylist = item.birthday.split('-')
                 item.birthday = birthdaylist[0]+'年'+birthdaylist[1]+'月'+birthdaylist[2]+'日'
              })
          }
        })
    },
    deleteReport(id){
        var that = this
        Dialog.alert({
          title: '提示',
          message: '此操作将删除该条数据, 是否继续?',
        }).then(() => {
          const url = '/xcxlove/del'
          postAction(url, {Id:id}).then(res => {
            Toast.success('删除成功');
            that.getList()
          })
        });
    }
  }
}
</script>
<style scoped>
.container{
    width: 100%;
    min-height: 100vh;
    background-image: url('../assets/pink.jpg');
    /* background-image: linear-gradient(rgb(250,240,241), rgb(255,255,255));; */
    /* background-image: linear-gradient(to bottom right, rgb(242,197,204),rgb(242,197,204), rgb(219,49,111)); */
    background-size: 100% 100%;
    background-repeat: no-repeat;

}
.listBox{
  width: 75%;
  height: 50px;
  margin: 16px auto;
  padding: 10px 6px 10px 10px;
  border-radius: 0px 20px 20px 20px;
  box-shadow: 0 10px 6px -6px #777;
  background: rgb(251,245,245);
}
/deep/.van-field__label{
   width: 6em;
}
/deep/.van-cell{
  background: rgb(233,230,230);
  border-radius: 10px;
}
/deep/.van-field__label{
  color: #333;
}
/deep/.van-radio__label{
  color: #333;
}
/deep/ input{
  color: #333;
  background-color: transparent;
}
.warningWord{
  text-align: right;
  color: rgb(46,211,120);
  font-size: 12px;
  padding-right: 10px;
}
.user-name{
  text-align: left;
}
.mt6{
  margin-top: 6px;
}
.user-info{
  display: flex;
  align-items: center;
  padding-top: 30px;
  padding-left: 30px;
}
.img-head{
  width:  60px;
  height: 60px;
  border: 2px solid white;
  border-radius: 30px;
}
.logoStyle{
  width: 60px;
  height: 60px;
  border-radius: 30px;
}
.button1Style{
  background:rgb(242,197,204);
  border:1px solid rgb(242,197,204);
  font-size: 20px;
  width:150px;
  border-radius:6px;
}
.button2Style{
  background:rgb(182,182,182);
  border:1px solid rgb(182,182,182);
  font-size: 20px;
  width:150px;
  border-radius:6px;
}
</style>


