<template>
  <div class="container">
     <div class="user-info">
       <h3>喵星君大大易学课程</h3>
       <div class="img-head">
            <img :src="headimg" class="logoStyle" alt="">
        </div>
        <div class="margin:10px 0px">{{nickname}}</div>
        <van-button v-if="!headimg" style="width:60%;height:30px;margin:20px 10px" round block type="info" native-type="submit" @click="gotoCode">请重新登录</van-button>
     </div>
    <div class="user-info">
       <div>请选择课程金额：</div>
       <el-select v-model="number"  style="width: 60%;margin-top:6px" placeholder="请选择金额">
            <el-option v-for="(item, index) in numberList" :key="index" :label="`${item}元`" :value="item"></el-option>
        </el-select>
        <van-button style="width:60%;height:36px;margin:20px 10px" round block type="info" native-type="submit" @click="gotoPay">支付</van-button>
    </div>
  </div>
</template>
<script>
import wxlogin from 'vue-wxlogin';
import wx from 'weixin-jsapi'
import {postAction,getAction } from '@/api/manage'
export default {
  components: { wxlogin },
  name: 'Login',
  data() {
    return {
      number:1000,
      numberList:[
        100.01,800,880,1000,2000
      ],
      headimg:'',
      openid:'',
      nickname:''
    }
  },
  mounted() {
    this.getlogin()
  },
  methods: {
     gotoCode(){
        if(this.isweixin()){
          console.log(this.isweixin())
          //window.location.href="https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxbb2af162829dc7fe&redirect_uri=http%3A%2F%2Fzy.miaoxingjundd.cn%2F&response_type=code&scope=snsapi_base&state=123#wechat_redirect"
          window.location.href="https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxbb2af162829dc7fe&redirect_uri=https%3A%2F%2Fonline.miaoxingjundd.com%2FbuyLession&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect"
          //  this.$router.push({path:'/reportList', query: {code: "1234"}})
       }
      //  else {
      //     this.$router.push({path:'/reportList', query: {code: "1234"}})
      //  }
     },
     getlogin(){
       if(this.$route.query.code){
        this.getOpenID()
       } else {
        this.gotoCode()
       }
     },
    getOpenID(){
      const url = '/buy/vxlogin'
      // const url = '/pr/test'
          postAction(url, {code:this.$route.query.code}).then( res => {
            this.userInfo = res.data.data
            this.headimg = this.userInfo.headimgurl;
            this.openid = this.userInfo.openid;
            this.nickname = this.userInfo.nickname
          })
    },
    isweixin(){
       var ua = window.navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == 'micromessenger') {
            return true
        } else {
            return false
        }
     },
    gotoPay(){
      const url = '/buy/wxpay'
      var that = this
      getAction(url, {number: this.number*100, openid: this.openid}).then( res => {
          if(res.data.code === 200){
            const result = res.data.msg.result
            wx.config({
              debug: false, // 这里一般在测试阶段先用ture，等打包给后台的时候就改回false,
              appId: result.appId, // 必填，公众号的唯一标识
              timestamp: result.timeStamp, // 必填，生成签名的时间戳
              nonceStr: result.nonceStr, // 必填，生成签名的随机串
              signature: result.paySign, // 必填，签名
              jsApiList: ['checkJsApi','chooseWXPay'] // 必填，需要使用的JS接口列表
            })
            wx.ready(() => {
              wx.checkJsApi({
                jsApiList: ['chooseWXPay'],
                success:function(res){
                  console.log("success")
                  wx.chooseWXPay({
                      timestamp: result.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
                      nonceStr: result.nonceStr, // 支付签名随机串，不长于 32 位
                      package: result.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
                      signType: result.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
                      paySign: result.paySign, // 支付签名
                      success: function (res) {  // 支付成功后的回调函数
                        alert("支付成功");
                        // window.location.href = "https://try.miaoxingjundd.cn/careerResult?re_id=" + that.$route.query.re_id + "&openid=" + sessionStorage.getItem("us_openid")
                        // that.$router.push({path:'/careerResult', query:{re_id:this.$route.query.re_id, openid:sessionStorage.getItem("us_openid")}})
                      },
                      fail: function (res) {
                        alert("支付失败");
                      }
                    })
                },
                fail:function(res){
                  console.log("fail");
                  console.log(res) }
              })
            })
            wx.error(err => {
              Toast.fail(err)
            })
          }else{
            Toast.fail(res.data.msg);
          }
      })
    },
  }
}
</script>
<style scoped>
.container{
   background:#f0f2f5;
   padding: 50px;
   height: 100vh;
}
.user-info{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 16px;
  padding-left: 20px;
}
.img-head{
  width: 80px;
  height: 80px;
  border: 2px solid white;
  border-radius: 40px;
}
.logoStyle{
  width: 80px;
  height: 80px;
  border-radius: 40px;
}
.buttonColor{
  background: #2DDA82;
  color: white;
}
.buttonColor2{
  background: #35FF79;
  color: white;
  border: 2px solid #35FF79;
}
</style>
