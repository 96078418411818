<template>
  <div>
    <div class="content-head">
        <el-button style="float: left; padding: 3px 4px;margin-right:10px;color:#FF6B3B" @click="$router.push({name: 'MessageList'})" icon="el-icon-caret-left" circle></el-button>
        <span>返回</span>
    </div>
      <!-- <div class="twoPeople">
        <div>
            <i v-if="chatPepole.name_sex === '女'" class="iconfont icon-nvsheng iconSize" />
            <i v-else class="iconfont icon-nansheng iconSize" />
            <span class="ml5">{{chatPepole.name}}</span>
        </div>
        <div>
            <i v-if="chatPepole.host_sex === '女'" class="iconfont icon-nvsheng iconSize" />
            <i v-else class="iconfont icon-nvsheng iconSize" />
            <span class="ml5">{{chatPepole.host_name}}</span>
        </div>
      </div>
      <el-card class="card">
        <div v-if="massageList.length">
            <div v-for="(item, index) in massageList" :class="[item.status === 'receive'?'box-left':'box-right']" :key="index" >
              <div :class="[item.status === 'receive'?'big-box-shou':'big-box-fa']">
                  <div class="timeFont">{{item.time}}</div>
                  <div class="box-bord">
                    <span>{{item.message}}</span>
                  </div>
              </div>
            </div>
        </div>
        <div style="display:flex; margin-top:20px">
          <el-input v-model="sendText" style="width:200px;margin-right:10px;flex:1" placeholder="请输入内容"></el-input>
          <el-button type="primary" :loading="loading" size="small" style="margin-left：20px" @click="send()">发送</el-button>
        </div>
      </el-card> -->
        <div class="width-24 fs14">
            <div class="content" id="MyCenter" @click="back"
                :style="{height:'80vh' }">
                <ul class="pop__clearfix" v-for="(item,index) in msglist" :key="index">
                    <!-- 显示最近一次的聊天时间 -->
                    <li class="time">{{item.time}}</li>
                    <!-- 用户 -->
                    <li class="pop__chat-msg-me" v-if="item.turn=='right'">
                        <div class="pop__chat-msg-content">
                            <div class="pop__chat-msg-msg" v-if="item.content"><span>{{item.content}}</span></div>
                        </div>
                        <span class="pop__chat-msg-avatar">
                            <img style="width:100%" :src="headimg">
                            <!-- <img src="xxx.jpg" large="xxx_3x.jpg" preview="2" preview-text="描述文字"> -->
                        </span>
                    </li>

                    <!-- 好友 -->
                    <li class="pop__chat-msg-me2" v-if="item.turn=='left'">
                        <span class="pop__chat-msg-avatar">
                            <img style="width:100%" :src="image">
                        </span>
                        <div class="pop__chat-msg-content2">
                            <div class="pop__chat-msg-msg2" v-if="item.content"><span>{{item.content}}</span></div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="Bottom">
                <div class="state">
                    <!-- 输入框 -->
                    <textarea class="ml10" v-if="Texts" id="t" autofocus ref="myInput" @click="textareas"
                        v-model="TentText"></textarea>
                    <!-- 表情 -->
                    <button class="expression" @click="expression">
                        <img :src="expsions?'../assets/j.png':'../assets/b.png'" alt="">
                    </button>
                    <!-- 发送图片 -->
                    <button class="file" @click="file" v-if="TentText?false:true"></button>
                    <!-- capture="camera" 相机拍照 -->
                    <input type="file" style="display: none;" accept="image/*" multiple id="p" name="file"
                        @change="onChooseImage($event)" />
                    <!-- 发送文字内容 -->
                    <el-button :loading="loading" class="sendout" @click="sendout" v-if="TentText?true:false">发</el-button>
                </div>
                <div class="express" v-if="expsions">
                    <div style="font-size: 24px; padding: 0.1rem 0.12rem; " @click="copy(item,index)"
                        v-for="(item,index) in emolis" :key="index">{{item.char}}</div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
import { postAction, getAction } from '@/api/manage'
import { mapState } from 'vuex'
import { Toast } from 'vant';
export default {
  data () {
    return {
      page: 1,
      massageList: [
      ],
      msglist: [
      ],
      chatPepole: {
        host_name: '',
        host_sex: '',
        name: '',
        name_sex: ''
      },
      listLoading: false,
      dialogVisible: false,
      form: {},
      sendText: '',
      loading: false,
        screenWidth: '',     // 屏幕宽
        screenHeight: '',    // 屏幕高
        list: [
            { id: 1, cont: '山不在高，有仙则名。' },
            { id: 0, time: '2011-7-9 5:21' },
            { id: 2, cont: '水不在深，有龙则灵。' },
            { id: 2, cont: '斯是陋室，惟吾德馨。' },
            { id: 1, cont: '苔痕上阶绿，草色入帘青。谈笑有鸿儒，往来无白丁。' },
            { id: 2, cont: '可以调素琴，阅金经.' },
            { id: 1, cont: '无丝竹之乱耳，无案牍之劳形。' },
            { id: 1, cont: '南阳诸葛庐，西蜀子云亭。孔子云：何陋之有？' },
            { id: 1, img: '../Image/123.jpg' },
            { id: 0, time: '2014-7-9 5:21' },
            { id: 2, cont: '这位兄台文韬武略，一看就是状元之才啊' },
            { id: 1, cont: '过奖了，岂敢岂敢' },
            { id: 1, cont: '兄台不入到寒舍一聚，把酒言欢，吟诗作赋如何' },
            { id: 2, cont: '甚好' },
            { id: 1, cont: '兄台请😀' }],
        emolis: [
            { "char": "😍" },
            { "char": "😁" },
            { "char": "😘" },
            { "char": "🍎" },
            { "char": "🍏" },
            { "char": "🍊" },
            { "char": "🍋" },
            { "char": "😖" },
            { "char": "😉" },
            { "char": "😏" },
            { "char": "😏" },
            { "char": "🍒" },
            { "char": "🍇" },
            { "char": "🍉" },
            { "char": "🍓" },
            { "char": "🍑" },
            { "char": "🍈" },
            { "char": "🍌" },
            { "char": "🍐" },
            { "char": "🍍" },
            { "char": "🍆" },
            { "char": "🍅" },
            { "char": "🍠" },
            { "char": "🌽" },
            { "char": "😳" },
            { "char": "😡" },
            { "char": "😓" },
            { "char": "😭" },
            { "char": "😱" },
            { "char": "😜" },
            { "char": "😰" },
            { "char": "😢" },
            { "char": "😚" },
            { "char": "😄" },
            { "char": "😀" },
            { "char": "😪" },
            { "char": "😣" },
            { "char": "😔" },
            { "char": "😠" },
            { "char": "😌" },
            { "char": "😀" },
            { "char": "😝" },
            { "char": "😂" },
            { "char": "😥" },
            { "char": "😃" },
            { "char": "😞" },
            { "char": "😒" },
            { "char": "😀" },
            { "char": "👽" },
            { "char": "😄" },
            { "char": "😯" },
            { "char": "😂" },
            { "char": "😈" },
            { "char": "😀" },
            { "char": "😐" },
            { "char": "😃" },
            { "char": "😠" },
            { "char": "😑" },
            { "char": "😥" },
            { "char": "😬" },
            { "char": "😟" },
            { "char": "😢" },
            { "char": "😦" },
            { "char": "😱" },
            { "char": "😵" },
            { "char": "😴" },
            { "char": "😧" },
            { "char": "😙" },
            { "char": "😖" },
            { "char": "😛" },
            { "char": "😭" },
            { "char": "😒" },
            { "char": "😗" },
            { "char": "😚" },
            { "char": "😌" },
            { "char": "😪" },
            { "char": "😻" },
            { "char": "😏" },
            { "char": "🙆" },
            { "char": "😽" },
            { "char": "😄" },
            { "char": "😀" },
            { "char": "😕" },
            { "char": "😂" },
            { "char": "😅" },
            { "char": "😐" },
            { "char": "😃" },
            { "char": "😆" },
            { "char": "😑" },
            { "char": "😮" },
            { "char": "😨" },
            { "char": "😢" },
            { "char": "😦" },
            { "char": "😱" },
            { "char": "😵" },
            { "char": "😤" },
            { "char": "😶" },
            { "char": "😀" },
            { "char": "😝" },
            { "char": "😎" },
            { "char": "😞" },
            { "char": "😋" },
            { "char": "😔" },
            { "char": "😀" },
            { "char": "😒" },
            { "char": "😜" },
            { "char": "😌" },
            { "char": "😗" },
            { "char": "😏" },
            { "char": "🙊" },
            { "char": "👦" },
            { "char": "👩" },
            { "char": "😄" },
            { "char": "😃" },
            { "char": "😀" },
            { "char": "😀" },
            { "char": "😉" },
            { "char": "😗" },
            { "char": "😙" },
            { "char": "😜" },
            { "char": "😝" },
            { "char": "😛" },
            { "char": "😁" },
            { "char": "😌" },
            { "char": "💛" },
            { "char": "😒" },
            { "char": "😞" },
            { "char": "😣" },
            { "char": "😀" },
            { "char": "😂" },
            { "char": "😭" },
            { "char": "😪" },
            { "char": "😥" },
            { "char": "😅" },
            { "char": "😀" },
            { "char": "😩" },
            { "char": "😠" },
            { "char": "😤" },
            { "char": "😖" },
            { "char": "😋" },
            { "char": "😷" },
            { "char": "😴" },
            { "char": "😟" },
            { "char": "😦" },
            { "char": "😈" },
            { "char": "👿" },
            { "char": "😬" },
            { "char": "😯" },
            { "char": "😶" },
            { "char": "😇" },
            { "char": "😑" },
            { "char": "🍡" },
            { "char": "🍲" },
            { "char": "🍱" },
            { "char": "🍔" },
            { "char": "🍤" },
            { "char": "🍕" },
            { "char": "🍮" },
            { "char": "🍩" },
            { "char": "🍗" },
            { "char": "🍝" },
            { "char": "🍳" },
            { "char": "🍭" },
            { "char": "🍪" },
            { "char": "🍦" },
            { "char": "🍖" },
            { "char": "🍟" },
            { "char": "🍯" },
            { "char": "🍬" },
            { "char": "🍰" },
            { "char": "🍧" },
            { "char": "🍵" },
            { "char": "🍹" },
            { "char": "🍺" },
            { "char": "💔" },
            { "char": "💘" },
            { "char": "💜" },
            { "char": "💛" },
            { "char": "💚" },
            { "char": "💙" },
            { "char": "💬" },
            { "char": "💩" },
            { "char": "👍" },
            { "char": "👎" },
            { "char": "👊" },
            { "char": "✌" },
            { "char": "👌" },
            { "char": "🐮" },
            { "char": "🐧" },
            { "char": "🐷" },
            { "char": "🐍" },
            { "char": "🐆" },
            { "char": "🐠" },
            { "char": "🐒" },
            { "char": "🐬" },
            { "char": "🐞" },
            { "char": "🐌" },
            { "char": "🐾" },
            { "char": "🐜" },
            { "char": "🐙" },
            { "char": "🐵" },
            { "char": "🐉" },
            { "char": "🚝" },
            { "char": "🚋" },
            { "char": "🚃" },
            { "char": "🚌" },
            { "char": "🚍" },
            { "char": "🚙" },
            { "char": "🚘" },
            { "char": "🚗" },
            { "char": "🚕" },
            { "char": "🚖" },
            { "char": "🚛" },
            { "char": "🚓" },
            { "char": "🚒" },
            { "char": "🚲" },
            { "char": "🍸" }
        ],
        TentText: '',
        getBrowString: '',
        hidden: true,
        Press: false,
        Texts: true,
        active_search: true,
        expsions: false,
        faceList: '',
    }
  },
  computed: {
    username () {
      return this.$route.query.username
    },
    image(){
     return this.$route.query.image 
    },
    userinfoLove() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.$store.getters.getUserinfoLove || {};
    },
    openid() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.userinfoLove.jsopenid;
    },
    headimg() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.userinfoLove.image;
    }
  },
    updated() {
        this.scrollToBottom();
    },
    watch: {
        //每次变化，input获取焦点
        active_search: function (a, b) {
            if (b == true) {
                this.$refs.myInput.focus();
            }
        },
    },
  created () {
    this.message();
	  // this.timer = setInterval(()=>{
    //   this.message();
    // },10000);
  },
    mounted() {
        // 页面加载完成后显示
        setTimeout(function () {
            var container = document.getElementById("MyCenter");
            container.scrollTop = container.scrollHeight * container.clientHeight
            console.log(container.scrollTop)
        }, 100);//延迟5000毫米
        // 监听屏幕可视区域的宽高
        window.onresize = () => {
            return (() => {
                this.screenWidth = document.documentElement.clientWidth;
                this.screenHeight = document.documentElement.clientHeight;
                // 监听光标跟着最后一个字符
                var textarea = document.getElementById("t");
                textarea.onfocus = function () {
                    moveCaretToEnd(textarea);
                    // Work around Chrome's little problem
                    window.setTimeout(function () {
                        moveCaretToEnd(textarea);
                    }, 1);
                };
            })();
          
        };
        this.scrollToBottom();
    },
  activated () {
    this.message();
  },
  // watch: {
  //   massageList() {
  //     this.timer() 
  //   }
  // },
  destroyed() {
    clearInterval(this.timer);
  },
  methods: {
    message () {
      const url = '/message/content'
      postAction(url, { me:this.openid,username: this.username }).then(res => {
        this.msglist = res.data.msg.Info
      })
    },
    sendout () {
      const url = '/message/chat/send'
      this.loading = true
      if(this.TentText === ''){
         Toast.fail('发送内容不能为空');
         return
      }
      setTimeout(() => {
        postAction(url, { me:this.openid,username: this.username, content: this.TentText }).then(res => {
          this.TentText = '';
          this.message()
          this.loading = false
        }).catch(err => {
          console.log('err', err)
          this.loading = false
        })
      }, 500)
    },
    scrollToBottom() {
        this.$nextTick(() => {
            var container = document.getElementById("MyCenter");
            // container.scrollTop = container.scrollHeight = container.clientHeight;
            container.scrollTop = container.scrollHeight * container.clientHeight
            // container.scrollTop = container.clientHeight
            console.log(container.scrollTop)
        })
    },
    // 点击获取当前的图片
    imgs(item) {

    },
    back() {
        if (this.expsions) {
            this.expsions=false 
        }
    },
    // 表情
    expression() {
        if (this.Press) {
            this.Press = !this.Press; this.Texts = !this.Texts; this.expsions = !this.expsions;
        } else {
            this.expsions = !this.expsions;
        }
        if (this.expsions == false) {
            this.$refs.myInput.focus();
        }
    },
    // 图片
    file() {
        //表情是否开启
        if (this.expsions) {
            this.expsions = false
        }
        document.getElementById('p').click()
    },
    onChooseImage: function (event) {
        //使用FileReader对文件对象进行操作
        let list = this.list
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]); //将读取到的文件编码成Data URL
        reader.onload = function () { //读取完成时
            var replaceSrc = reader.result; //文件输出的内容
            list.push({ id: 1, img: replaceSrc },)
        };
        console.log(this.list)
    },
    // 发送
    send() {
        socket.send(this.TentText);
        this.list.push({ id: 1, cont: this.TentText })
        this.TentText = '';
        if (this.expsions == false) {
            this.$refs.myInput.focus();
        }

    },
    // 把表情复制到输入框中
    copy(item, index) {
        this.getBrowString = this.emolis[index].char;
        this.TentText += this.getBrowString;
    },
    textareas() {
        this.expsions = false
    },
  }
}
</script>
<style scoped>
  .box-left{
    display: flex;
    justify-content: flex-start;
  }
  .twoPeople{
    display: flex;
    justify-content: space-between;
  }
  .box-right{
    display: flex;
    justify-content: flex-end;
  }
  .timeFont{
    margin-left: 8px;
    font-size: 12px;
  }
  .big-box-fa span{
    background-color: #CDF3E4;
    padding: 5px 8px;
    height: 20px;
    display: inline-block;
    border-radius: 4px;
    margin:10px 0 10px 10px;
    position: relative;

  }
  .big-box-fa span::after{
    content: '';
    border: 8px solid #FFF;
    border-left: 8px solid #CDF3E4;
    position: absolute;
    top: 6px;
    right: -16px;
  }
  .big-box-shou span{
    background-color: #F08BB4;
    padding: 5px 8px;
    height: 20px;
    display: inline-block;
    border-radius: 4px;
    margin:10px 0 10px 10px;
    position: relative;

  }
  .big-box-shou span::after{
    content: '';
    border: 8px solid #FFF;
    border-right: 8px solid #F08BB4;
    position: absolute;
    top: 6px;
    left: -16px;
  }
  .iconSize{
    font-size: 20px;
  }
  .fr{
     align-self:flex-end
  }
  .fl{
     align-self:flex-start
  }
  .body-item{
    display:flex;
    justify-content:space-between
  }
  .small-font{
    font-size: 12px;
    color: #686868;
    margin-right:20px;
  }
  .right-corner{
    display: inline-block;
    width: 16px;
    height: 16px;
    font-size: 8px;
    position:absolute;
    left:-18px;
    top:-8px;
    text-align: center;
    background-color: #FDEDBE;
    border:2px solid #D2EDC8;
    border-radius:10px
  }
  .name-position{
    position: relative;
  }
  .content-head{
    display: flex;
    align-items: center;
    height: 40px;
    width: 100%;
    background-image:url("../assets/head-bg.png");
    background-size:cover;
    color: white;
    font-size: 14px;
    font-weight: 600;
  }

.time {
    text-align: center;
    font-size: 16px;
    margin: 0.25rem 0;
    font-weight: 500;
}

.content {
    background: #EBEBEB;
    padding: 0.2rem;
    overflow: scroll;
    box-sizing: border-box;
    padding-bottom: 0.8rem;
    text-align:left;
}

.pop__chat-msg-me {
    display: flex;
    justify-content: flex-end;
    margin-bottom: .36rem
}

.pop__chat-msg-me2 {
    display: flex;
    margin-bottom: .36rem
}

.pop__chat-msg-content {
    width: 72%;
    display: flex;
    justify-content: flex-end;
}

.pop__chat-msg-content2 {
    width: 72%;
}

.pop__chat-msg-content .pop__chat-msg-msg {
    float: right;
    background-color: rgb(253,93,114);
    border-color: rgb(253,93,114);
}

.pop__chat-msg-msg {
    background-color: rgb(253,93,114);
    border-color: rgb(253,93,114);
    border-style: solid;
    border-width: 0.02rem;
    border-radius: 2px;
    color: #333;
    float: left;
    font-size: 14px;
    margin: 0 .2rem;
    padding: 0.15rem .2rem;
    max-width: 90%;
    position: relative;
    color: #fff;
}

.pop__chat-msg-msg1 {
    /* background-color: #fff;
    border-color: #fff;
    border-style: solid; */
    border-width: 1px;
    border-radius: 2px;
    color: #333;
    float: left;
    font-size: 14px;
    margin: 0 .2rem;
    /* padding: .15rem .2rem; */
    max-width: 90%;
    position: relative;
}

.pop__chat-msg-msg2 {
    background-color: #fff;
    border-color: #fff;
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    color: #333;
    float: left;
    font-size: 14px;
    margin: 0 .2rem;
    padding: .15rem .2rem;
    max-width: 90%;
    height: 90%;
    position: relative;
}

.pop__chat-msg-msg22 {
    /* background-color: #fff;
    border-color: #fff;
    border-style: solid; */
    border-width: 1px;
    border-radius: 2px;
    /* color: #333; */
    float: left;
    font-size: 14px;
    margin: 0 .2rem;
    max-width: 90%;
    position: relative;
}

.pop__chat-msg-avatar {
    display: block;
    overflow: hidden;
    height: 50px;
    width: 50px;
    border-radius: 5px;
}

.pop__clearfix:after {
    content: "";
    clear: both;
    display: block;
}

.pop__chat-msg-content .pop__chat-msg-msg:before {
    background: inherit;
    border-color: inherit;
    right: -.11rem;
    transform: translate(0, -50%) rotate(45deg);
}

.pop__chat-msg-content .pop__chat-msg-msg:before {
    content: '';
    display: inline-block;
    border-style: solid;
    border-width: .01rem .01rem 0 0;
    height: 5px;
    width: 5px;
    position: absolute;
    top: .3rem;
}

.pop__chat-msg-msg2:before {
    background: inherit;
    border-color: inherit;
    left: -0.11rem;
    transform: translate(0, -50%) rotate(45deg);
}

.pop__chat-msg-msg2:before {
    content: '';
    display: inline-block;
    border-style: solid;
    border-width: .01rem .01rem 0 0;
    height: 5px;
    width: 5px;
    position: absolute;
    top: .3rem;
}

/*  */

.pop__chat-msg-content .pop__chat-msg-msg1:before {
    background: inherit;
    border-color: inherit;
    right: -.11rem;
    transform: translate(0, -50%) rotate(45deg);
}
.Bottom {
    width: 100%;
    position: fixed;
    bottom: 10;
    left: 0;
    background: #f7f7f7;
    box-shadow: 0px 0px 0.3rem 0px rgba(245, 245, 245, 0.25);
    /* display: flex; */
    border-top: 1px solid rgb(228, 228, 228);
    padding: 0.31rem 0 0;
}

.Bottom button {
    width: 30px;
    height: 30px;
    display: flex;
    align-self: center;
    text-align: center;
    margin: 0 0.14rem;
    border-radius: 50%;
    border: none;
    outline: none;
}

.expression {
    background-image: url(../assets/b.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.file {
    background-image: url(../assets/s.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.sendout {
    border-radius: 0.1rem;
    line-height: 30px;
    padding: 0 8px;
    font-size: 14px;
    font-weight: 500;
    background: #0BA198;
    color: #fff;
}

.Bottom input {
    width: 63%;
    height: 0.76rem;
    display: flex;
    align-self: center;
    border: none;
    border-radius: 0.1rem;
    outline: none;
    font-size: 14px;
    padding-left: 0.1rem;
    word-wrap: break-word;
}

.Bottom textarea {
    height: 30px;
    display: flex;
    flex: 1;
    align-self: center;
    border: none;
    border-radius: 0.1rem;
    outline: none;
    font-size: 14px;
    padding-left: 0.1rem;
    word-wrap: break-word;
    padding-top: 0.1rem;
    resize: none;
}

.Press {
    width: 65%;
    height: 0.76rem;
    border-radius: 0.1rem;
    background: #fff;
    padding: 0.16rem 1.75rem;
    font-size: 14px;
    text-align: center;
    font-weight: 500;
    margin: 0;
}

.Press:active {
    background: #EBEBEB;
}

.state {
    display: flex;
    padding-bottom: 0.31rem;
}

.express {
    transition: all 2s;
    height: 250px;
    display: flex;
    justify-content: flex-start;
    box-sizing: border-box;
    padding: 0.2rem 0.1rem 0;
    flex-wrap: wrap;
    overflow: scroll;
}
</style>

