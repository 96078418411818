<template>
  <div class="container">
    <div class="formClass">
      <van-form >
        <van-field
          v-model="username"
          name="用户名"
          label="用户名"
          placeholder="用户名"
        />
        <van-field name="radio" label="性别" style="margin:30px 0px">
          <template #input>
            <van-radio-group v-model="sex" direction="horizontal">
              <van-radio name="1">男生</van-radio>
              <van-radio name="2">女生</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          readonly
          clickable
          label="出生地"
          name="area"
          :value="provincecity"
          style="margin:30px 0px"
          placeholder="点击选择省市区"
          @click="showArea = true"
        />
        <van-popup v-model="showArea" position="bottom">
          <van-area
            :area-list="areaList"
            :columns-num="2"
            @confirm="onConfirmCity"
            @cancel="showArea = false"
          />
        </van-popup>
         <!-- 时间选择器-->
        <van-field
          readonly
          clickable
          name="datetimePicker"
          :value="datetime"
          label="出生时间"
          placeholder="点击选择出生时间"
          style="margin:30px 0px"
          @click="showPicker2 = true"
        />
        <van-popup v-model="showPicker2" position="bottom">
          <van-datetime-picker
            type="datetime"
            v-model="currentDate"
            title="选择完整时间"
            :min-date="minDate"
            :max-date="maxDate"
            @confirm="onConfirmTime"
            @cancel="showPicker2 = false"
          />
        </van-popup>
        <div class="warningWord">
          <div class="mt6">不确定时间，默认为00:00</div>
          <div class="mt6">请务必确认输入无误</div>
          <div class="mt6">报告为文字类产品，一经售出概不退款</div>
        </div>
        <div style="margin: 20px 16px;">
          <van-button :loading="loading" loading-text="请求中..." style="background:rgb(46,211,121); font-size: 18px;" round block type="info" native-type="submit" @click="onSubmit">新增报告</van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import { areaList } from '@vant/area-data';
import { Toast } from 'vant';
import {postAction} from '@/api/manage'
export default {
  name: 'success',
  data() {
    return {
      username: '',
      sex:'',
      password: '',
      provincecity:'',
      showArea: false,
      areaList, // 数据格式见 Area 组件文档
      datetime: '',
      showPicker: false,
      showPicker2: false,
      minDate: new Date(1960, 0, 1),
      maxDate: new Date(2025, 10, 1),
      currentDate: new Date(1990, 0, 1),
      sex: '女',
      loading:false
   };
  },
  computed:{
    userinfoCareer() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.$store.getters.getUserinfoCareer || {};
    },
    uid() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.userinfoCareer.us_id;
    },
    openid() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.userinfoCareer.us_openid;
    },
    unionid() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.userinfoCareer.us_unionid;
    },
  },
  methods:{
            onSubmit(){
              if(this.datetime == '' || this.provincecity == ''){
                Toast.fail('输入不能为空');
                return
              }
                let url = '/pr/add_report'
                const city = this.provincecity.split('/')
                const params = {
                    birthday: this.datetime,
                    name: this.username,
                    uid: this.uid,
                    unionid: this.unionid,
                    openid: this.openid,
                    sex: this.sex,
                    city: city[1]
                }
                this.loading = true
                postAction(url,params)
                .then((res) => {
                   if(res.data.code === 201){
                     Toast.success(res.data.msg);
                     this.$router.push({ path: '/reportList'})
                   }else{
                     Toast.fail(res.data.msg);
                   }
                })
                .finally((res) => {
                  this.loading = false
                })
            },
            onConfirmCity(values) {
              this.provincecity = values
                .filter((item) => !!item)
                .map((item) => item.name)
                .join('/');
                console.log('values',this.provincecity)
              this.showArea = false;
            },
            onConfirmTime(time) {
              console.log('values',time)
              this.datetime = this.parseTime(time,'{y}-{m}-{d}-{h}-{i}' )
              console.log('values',this.datetime)
              this.showPicker2 = false;
            },
            parseTime (time, cFormat) {
              if (arguments.length === 0) {
                return null
              }
              if (!time) return ''
              const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
              let date
              if (typeof time === 'object') {
                date = time
              } else {
                if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
                  time = parseInt(time)
                }
                if ((typeof time === 'number') && (time.toString().length === 10)) {
                  time = time * 1000
                }
                date = new Date(time)
              }
              const formatObj = {
                y: date.getFullYear(),
                m: date.getMonth() + 1,
                d: date.getDate(),
                h: date.getHours(),
                i: date.getMinutes(),
                s: date.getSeconds(),
                a: date.getDay()
              }
              const timeStr = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
                let value = formatObj[key]
                // Note: getDay() returns 0 on Sunday
                if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
                if (result.length > 0 && value < 10) {
                  value = '0' + value
                }
                return value || 0
              })
              return timeStr
            },
  }
}
</script>
<style scoped>
.container{
    width: 100%;
    height: 100vh;
    background-image: url('../assets/addBg.jpg');
    background-size: 99% 100%;
    background-repeat: no-repeat;
    color: white;
}
.formClass{
  width: 70%;
  margin: 0px auto;
  padding-top: 110px;
}
/deep/.van-field__label{
   width: 5.2em;
}
/deep/.van-cell{
  background: rgba(79,48,167, 0.8);
  border-radius: 10px;
}
/deep/.van-field__label{
  color: #f0f0f0; 
}
/deep/.van-radio__label{
  color: #f0f0f0; 
}
/deep/ input{
  color: #f0f0f0; 
  background-color: transparent;
}
.warningWord{
  text-align: right;
  color: rgb(46,211,120);
  font-size: 12px;
  padding-right: 10px;
}
.mt6{
  margin-top: 8px;
}
</style>


