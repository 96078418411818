<template>
    <div class="container">
        <div class="headercenter">
            <van-button @click="$router.go(-1)" plain round size="mini" icon="arrow-left" type="info" style="margin-right:20px"></van-button>
            <h2>断语生成</h2>
        </div>
        <el-form ref="dynamicValidateForm" :model="dynamicValidateForm" class="demo-dynamic">
            <div v-for="(domain, index) in dynamicValidateForm.wordList" :key="index" class="formList">
                <el-row class="row-center">
                    <el-col :span="5">
                        <el-form-item  :rules="[{ required:true, message: '输入不能为空'}]">
                            <el-select v-model="domain.type" style="width:100%" clearable placeholder="请选择前后运" @change="selectParentList">
                                <el-option v-for="(item,i) in typeList" :key="`${i}type`" :label="item" :value="item" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                     <el-col :span="5" class="ml10">
                        <el-form-item :rules="[{ required:true, message: '输入不能为空'}]">
                            <el-select v-model="domain.parent" style="width:100%" clearable placeholder="请选择母类型"  @change="selectSonList">
                                <el-option v-for="(item,i) in parentList" :key="`${i}par`" :label="item" :value="item" />
                            </el-select>
                        </el-form-item>
                     </el-col>
                     <el-col v-if="!(domain.parent.indexOf('原生')>= 0 || domain.parent.indexOf('用神')>= 0)" :span="5" class="ml10">
                        <el-form-item :rules="[{ required:true, message: '输入不能为空'}]">
                            <el-select v-model="domain.year" style="width:100%" clearable placeholder="请选择年份"  @change="selectSonList">
                                <el-option v-for="(item,i) in yearList" :key="`${i}par`" :label="item" :value="item" />
                            </el-select>
                        </el-form-item>
                     </el-col>
                      <el-col :span="5"  class="ml10">
                        <el-form-item  :rules="[{ required:true, message: '输入不能为空'}]">
                            <el-select v-model="domain.son" style="width:100%" clearable placeholder="请选择子类型">
                                <el-option v-for="(item,i) in sonList" :key="`${i}son`" :label="item" :value="item"/>
                            </el-select>                
                        </el-form-item>
                      </el-col>
                </el-row>
            </div>
            <div style="text-align:right; margin-top:10px">
            <el-button type="primary" icon="el-icon-plus" circle @click="addDomain" />
            <el-button v-if="dynamicValidateForm.wordList.length > 1" type="danger" icon="el-icon-minus" circle @click="removeDomain" />
            </div>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="output">生成断语</el-button>
        </div>
        <div class="mt10 pd20">
            <div class="mt10" v-for="(item,index) in word" :key="index">
                {{item}}
            </div>
            <div class="mt30">
                <el-button class="ml10" round size="small" style="color:#FF6B3B"
                    v-clipboard:copy="word"
                    v-clipboard:success="onCopy"
                    v-clipboard:error="onError"><i class="iconfont icon-aixin"/>
                </el-button>
            </div>
        </div>
    </div>
</template>
<script>
import { postAction } from '@/api/manage'
export default {
  data () {
    return {
      dynamicValidateForm: {
        wordList: [{
          type: '',
          parent: '',
          year:'',
          son: '',
          content: ''
        }]
      },
      dictList:[{
          key:'',
          value:''
      }],
      typeList:[],
      parentList:[],
      parentListTemp:[],
      sonList:[],
      listLoading:false,
      word:'',
      yearList:['2022', '2021','2020','2019','2018','2017','2016','2015','2014','2013','2012','2011']
    }
  },
  created(){
      this.getDict()
  },
  methods:{
    getDict(){
        const url = '/oa/getDict'
        postAction(url).then( res => {
		     if (res.data.code === 200) {
                this.dictList = res.data.msg
                this.typeList = this.dictList.map(item => item.tag)
                console.log('typeList',this.typeList)
		      } else {
                this.$message.error(res.data.msg)
		     }
          })
    },
    selectParentList(val){
      const tempList = this.dictList.find(item => item.tag === val).flag
      this.parentListTemp = tempList
      this.parentList = tempList.map(item => item.mum)
    },
    selectSonList(val){
      const tempList = this.parentListTemp.find(item => item.mum === val)
      this.sonList = tempList.son
    },
    output () {
      this.$refs.dynamicValidateForm.validate((valid) => {
        if (valid) {
        const url = '/oa/outword'
        postAction( url, this.dynamicValidateForm).then( res => {
		     if (res.data.code === 200) {
                 this.word = res.data.msg.word
		      } else {
              this.$message.error(res.data.msg)
		     }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    removeDomain () {
      const length = this.dynamicValidateForm.wordList.length
      if (length > 1) {
        this.dynamicValidateForm.wordList.splice(length - 1, 1)
      }
    },
    addDomain () {
      this.dynamicValidateForm.wordList.push({
          type: '',
          parent: '',
          son: '',
          content: ''
      })
    },
    // 复制成功
    onCopy (e) {
      this.$message({
        showClose: true,
        message: '复制成功',
        type: 'success'
      })
    //   this.$store.commit('SET_Nick_NAME', e.text)
    },
    // 复制失败
    onError (e) {
      this.$message.success('复制失败')
    }
  }
}
</script>
<style scoped>
.container{
  padding: 10px 0px;
}
.headercenter{
    display:flex;
    justify-content: center;
    align-items: center;
}
.time{
  display: flex;
  justify-content: space-between;
}
.line{
  display: flex;
  justify-content: center;
}
/deep/  .el-form-item{
  margin-bottom: 0px;
}
.info-card{
	width: 80%;
	padding: 2px;
}
.formList{
   border: 1px solid #ddd;
   padding: 4px;
   margin-top: 0px;
}
.dialog-footer{
	text-align: center;
}
 /deep/ .el-tabs--border-card > .el-tabs__header .el-tabs__item:not(.is-disabled){
	color: #000;
 }
  /deep/ .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
    color: #1890ff;
}

</style>
