<template>
  <div class="container">
      <div class="user-info">
        <div class="img-head">
            <!-- <img src="../assets/logo.jpg" class="logoStyle" alt=""> -->
            <img :src="headimg" class="logoStyle" alt="">
        </div>
        <div class="user-name ml10">
           <div class="mt6 fs18">{{name}}</div>
        </div>
      </div>
      <van-form >
        <div class="formClass">
          <van-field
            readonly
            clickable
            :disabled="disabledFlag"
            name="datetimePicker"
            :value="datetime"
            label="你的生日"
            style="margin:16px 0px"
            @click="selectDate"
          />
          <van-popup  v-model="showPicker2" position="bottom">
            <van-datetime-picker
              type="datetime"
              v-model="currentDate"
              title="选择完整时间"
              :min-date="minDate"
              :max-date="maxDate"
              @confirm="onConfirmTime"
              @cancel="showPicker2 = false"
            />
          </van-popup>
          <van-field
            readonly
            clickable
            :disabled="disabledFlag"
            label="你的出生地"
            name="area"
            :value="provincecity"
            style="margin:16px 0px"
            @click="selectCity"
          />
          <van-field name="radio" label="你的性别"  style="margin:16px 0px">
            <template #input>
              <van-radio-group v-model="sex" :disabled="disabledFlag" direction="horizontal">
                <van-radio name="男">男生</van-radio>
                <van-radio name="女">女生</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <van-popup v-model="showArea" position="bottom">
            <van-area
              :area-list="areaList"
              :columns-num="2"
              @confirm="onConfirmCity"
              @cancel="showArea = false"
            />
          </van-popup>
        </div>

        <div class="formClassOther">
          <van-field
            v-model="usernameOther"
            name="TA的昵称"
            label="TA的昵称"
            placeholder="TA的昵称"
          />
          <van-field
            readonly
            clickable
            name="datetimePicker"
            :value="datetimeOther"
            label="TA的生日"
            style="margin:16px 0px"
            @click="showPicker3 = true"
          />
          <van-popup v-model="showPicker3" position="bottom">
            <van-datetime-picker
              type="date"
              v-model="currentDate"
              title="选择完整时间"
              :min-date="minDate"
              :max-date="maxDate"
              @confirm="onConfirmTime2"
              @cancel="showPicker3 = false"
            />
          </van-popup>
          <van-field name="radio" label="TA的性别" style="margin:16px 0px">
            <template #input>
              <van-radio-group v-model="sexOther" direction="horizontal">
                <van-radio name="男">男生</van-radio>
                <van-radio name="女">女生</van-radio>
              </van-radio-group>
            </template>
          </van-field>
        </div>
      </van-form>

      <div class="mt20 ml40 mr40 tl lineheight22">
        <span class="fwbold">介绍</span>：<span style="font-style:15px">如果你想知道和一个人的关系(同性异性均可)，但是不知道Ta的具体时间，那么可以用这个小程序，了解你们俩相处的状态。我们会告诉你对方的短择概率(用于异性)，两个人性格上谁更强势，Ta的缺点分布和对于金钱的态度。因为没有具体时间，只是提供这一类人的大致状况。可点击<span class="fwbold" @click="imageView">预览。</span></span>
      </div>

      <div class="row-center" style="margin: 20px 0px">
        <van-button :loading="loading" loading-text="请求中..." class="button1Style" block type="info" native-type="submit" @click="onSubmit">
          开始测试<span>￥9.9</span>
        </van-button>
        <van-button :loading="loading" loading-text="请求中..." class="button2Style ml20" block type="info" @click="recordList">
          匹配记录
        </van-button>
      </div>
      <div @click="showPopup" style="text-align:center;color:#666;margin-top:20px;text-decoration: underline;cursor: pointer;">核销码</div>
      <div style="text-align:center;color:#666;margin-top:20px">
            <img src="../assets/kefu.jpg" style="width:30%"  alt="">
             <div style="font-size:14px;margin:10px;padding-bottom:20px">系统问题反馈请长按二维码，添加微信。</div>
      </div>
      <van-dialog v-model="show" title="核销码" show-cancel-button @confirm="submitPaycode">
         <div class="pd10">
            <van-field
              v-model="paycode"
              name="核销码"
              label="核销码"
              placeholder="请输入核销码"
            />
         </div>
      </van-dialog>
  </div>
</template>
<script>
import axios from 'axios'
import { areaList } from '@vant/area-data';
import { ImagePreview,Toast } from 'vant';
import {postAction,getAction } from '@/api/manage'
import wx from 'weixin-jsapi'
export default {
  name: 'success',
  components: {
    [ImagePreview.Component.name]: ImagePreview.Component,
  },
  data() {
    return {
      username: '',
      usernameOther:'',
      image:'../assets/reliableHome.jpeg',
      sex:'',
      sexOther:'男',
      password: '',
      provincecity:'',
      showArea: false,
      areaList, // 数据格式见 Area 组件文档
      datetime: '',
      datetimeOther: '',
      showPicker: false,
      showPicker2: false,
      showPicker3: false,
      minDate: new Date(1960, 0, 1),
      maxDate: new Date(2025, 10, 1),
      currentDate: new Date(1990, 0, 1),
      loading:false,
      infoWord:'',
      status:'',
      disabledFlag:false,
      show: false,
      paycode:'',
   };
  },
  computed:{
    userinfoLove() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.$store.getters.getUserinfoLove || {};
    },
    openid() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.userinfoLove.jsopenid;
    },
    unionid() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.userinfoLove.unionid;
    },
    headimg() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.userinfoLove.image;
    },
    name() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.userinfoLove.nickname;
    },
    code(){
      return this.$route.query.code ? this.$route.query.code : ''
    },
  },
  mounted(){
    if(!this.unionid){
      if(!this.code){
        var url = encodeURIComponent(window.location.protocol+"//"+window.location.hostname+ '/reliableRegister')
        window.location.href=`https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxbb2af162829dc7fe&redirect_uri=${url}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`
      } else {
         this.getJsLogin()
      }
    } else {
      console.log('有unionid')
      this.getLoveSingle()
    }
  },
  methods:{
            getJsLogin(){
                const url = '/xcxlove/double/jslogin'
                // const url = '/pr/test'
                    postAction(url, {code:this.code}).then( res => {
                      if(res.data.code == 400 || res.data.code == 200){
                          this.userInfo = res.data.msg
                          this.$store.commit('setUserInfoLove', this.userInfo)
                          this.getLoveSingle()
                          console.log('this.userInfo',this.userInfo,this.$store.getters)
                      }
                    })
            },
            getLoveSingle(){
                const url = '/xcxlove/double/getInfo'
                postAction(url, {unionid:this.unionid}).then(res => {
                  if (res.data.code == 200) {
                      this.info = res.data.msg
                      this.username = this.info.nickname
                      this.datetime = this.info.birthday
                      this.provincecity = this.info.city
                      this.sex = this.info.sex
                      this.status = this.info.status
                      this.disabledFlag = true
                  }
                })
            },
            onSubmitbackup(){
              this.$router.push({ path: '/reliableResult'})
              // if(this.datetime == '' || this.provincecity == ''){
              //   Toast.fail('输入不能为空');
              //   return
              // }
              //   let url = '/user/register'
              //   const city = this.provincecity.split('/')
              //   const dateList = this.datetime.split(' ')
              //   const params = {
              //   unionid: this.unionid,
              //   jsopenid: this.openid,
              //   image: this.headimg,
              //   nickname: this.name,
              //   sex: this.sex,
              //   province: city[0],
              //   city: city[1],
              //   date: dateList[0],
              //   time: dateList[1]
              //   }
              //   this.loading = true
              //   postAction(url,params)
              //   .then((res) => {
              //      if(res.data.code === 200){
              //        Toast.success(res.data.msg);
              //        this.$router.push({ path: '/loveThreeHome'})
              //      }else{
              //        Toast.fail(res.data.msg);
              //      }
              //   })
              //   .finally((res) => {
              //     this.loading = false
              //   })
            },
            recordList(){
              this.$router.push({ path: '/reliableList'})
            },
            imageView(){
              ImagePreview({images:['http://www.miaoxingjundd.com/reliableResult.jpg'], closeable: true});
            },
            onConfirmCity(values) {
              this.provincecity = values
                .filter((item) => !!item)
                .map((item) => item.name)
                .join('/');
                console.log('values',this.provincecity)
                const city = this.provincecity.split('/')
                this.provincecity = city[1]
              this.showArea = false;
            },
            selectCity(){
              if(!this.disabledFlag){
                this.showArea = true
              }
            },
            selectDate(){
              if(!this.disabledFlag){
                this.showPicker2 = true
              }
            },
            onConfirmTime(time) {
              console.log('values',time)
              this.datetime = this.parseTime(time,'{y}-{m}-{d}-{h}-{i}' )
              console.log('values',this.datetime)
              this.showPicker2 = false;
            },
            onConfirmTime2(time) {
              this.datetimeOther = this.parseTime(time,'{y}-{m}-{d}' )
              this.showPicker3 = false;
            },
            parseTime (time, cFormat) {
              if (arguments.length === 0) {
                return null
              }
              if (!time) return ''
              const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
              let date
              if (typeof time === 'object') {
                date = time
              } else {
                if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
                  time = parseInt(time)
                }
                if ((typeof time === 'number') && (time.toString().length === 10)) {
                  time = time * 1000
                }
                date = new Date(time)
              }
              const formatObj = {
                y: date.getFullYear(),
                m: date.getMonth() + 1,
                d: date.getDate(),
                h: date.getHours(),
                i: date.getMinutes(),
                s: date.getSeconds(),
                a: date.getDay()
              }
              const timeStr = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
                let value = formatObj[key]
                // Note: getDay() returns 0 on Sunday
                if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
                if (result.length > 0 && value < 10) {
                  value = '0' + value
                }
                return value || 0
              })
              return timeStr
            },
            onSubmit(){
              // 支付前需要等待评定2秒，才能跳转支付
              const loading = this.$loading({
                lock: true,
                text: '正在评定中，请稍后...',
                background: 'rgba(243, 198, 205, 0.9)'
              });
              setTimeout(() => {
                loading.close();
                const url = '/xcxlove/double/wxpay'
                var that = this
                if(Number(this.status) >= 6 && Number(this.status) < 10){
                    // 给内部人员开小灶
                    window.location.href = "https://online.miaoxingjundd.com/reliableResult?otherbirthday=" + that.datetimeOther + "&othersex=" + that.sexOther + "&othername=" + that.usernameOther
                } else {
                  if(this.datetime == '' || this.provincecity == '' || this.datetimeOther == '' || this.usernameOther == ''){
                    Toast.fail('输入不能为空');
                    return
                  }
                  getAction(url,
                    {
                    unionid: this.unionid,
                    openid: this.openid,
                    nickname: this.name,
                    image:this.headimg,
                    mesex:this.sex,
                    mecity:this.provincecity,
                    mebirthday:this.datetime,
                    otherbirthday: this.datetimeOther,
                    othersex: this.sexOther,
                    name:this.usernameOther
                    }).then( res => {
                      if(res.data.code === 200){
                        const result = res.data.msg.result
                        wx.config({
                          debug: false, // 这里一般在测试阶段先用ture，等打包给后台的时候就改回false,
                          appId: result.appId, // 必填，公众号的唯一标识
                          timestamp: result.timeStamp, // 必填，生成签名的时间戳
                          nonceStr: result.nonceStr, // 必填，生成签名的随机串
                          signature: result.paySign, // 必填，签名
                          jsApiList: ['checkJsApi','chooseWXPay'] // 必填，需要使用的JS接口列表
                        })
                        wx.ready(() => {
                          wx.checkJsApi({
                            jsApiList: ['chooseWXPay'],
                            success:function(res){
                              wx.chooseWXPay({
                                  timestamp: result.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
                                  nonceStr: result.nonceStr, // 支付签名随机串，不长于 32 位
                                  package: result.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
                                  signType: result.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
                                  paySign: result.paySign, // 支付签名
                                  success: function (res) {  // 支付成功后的回调函数
                                    alert("支付成功");
                                    // alert(JSON.stringify(res));
                                    window.location.href = "https://online.miaoxingjundd.com/reliableResult?otherbirthday=" + that.datetimeOther + "&othersex=" + that.sexOther + "&othername=" + that.usernameOther
                                    // that.$router.push({path:'/careerResult', query:{re_id:this.$route.query.re_id, openid:sessionStorage.getItem("us_openid")}})
                                  },
                                  fail: function (res) {
                                    alert("支付失败");
                                  }
                                })
                            },
                            fail:function(res){
                              console.log("fail");
                              console.log(res) }
                          })
                        })
                        wx.error(err => {
                          // Toast.fail(err)
                        })
                      }else{
                        Toast.fail(res.data.msg);
                      }
                  })
                }
              }, 2000);
            },
            showPopup() {
              this.show = true;
            },
            submitPaycode(){
              const url = '/xcxlove/paycode'
              postAction(url,
                    {
                    unionid: this.unionid,
                    paycode: this.paycode,
                    otherbirthday: this.datetimeOther,
                    othersex: this.sexOther,
                    othername:this.usernameOther
                    }).then( res => {
                      if(res.data.code === 200){
                        window.location.href = "https://online.miaoxingjundd.com/reliableResult?otherbirthday=" + this.datetimeOther + "&othersex=" + this.sexOther + "&othername=" + this.usernameOther
                      } 
                    })
            }
  }
}
</script>
<style scoped>
.container{
    width: 100%;
    height: 100vh;
    /* background-image: url('../assets/registerBg.png'); */
    /* background-image: linear-gradient(rgb(250,240,241), rgb(255,255,255));; */
    background-image: linear-gradient(rgb(242,197,204), rgb(255,255,255));;
    background-size: 99% 100%;
    background-repeat: no-repeat;

}
.formClass{
  width: 75%;
  margin: 20px auto;
  padding: 10px 20px;
  border-radius: 0px 30px 30px 30px;
  background: #fff;
  box-shadow: 0px 4px 6px #cccccc;
}
.formClassOther{
  width: 75%;
  margin: 20px auto;
  padding: 10px 20px;
  border-radius: 30px 0px 30px 30px;
  box-shadow: 0px 4px 6px #cccccc;
  background: #fff;
}
/deep/.van-field__label{
   width: 6em;
}
/deep/.van-cell{
  background: rgb(233,230,230);
  border-radius: 10px;
}
/deep/.van-field__label{
  color: #333;
}
/deep/.van-radio__label{
  color: #333;
}
/deep/ input{
  color: #333;
  background-color: transparent;
}
.warningWord{
  text-align: right;
  color: rgb(46,211,120);
  font-size: 12px;
  padding-right: 10px;
}
.user-name{
  text-align: left;
}
.mt6{
  margin-top: 6px;
}
.user-info{
  display: flex;
  align-items: center;
  padding-top: 30px;
  padding-left: 30px;
}
.img-head{
  width:  60px;
  height: 60px;
  border: 2px solid white;
  border-radius: 30px;
}
.logoStyle{
  width: 60px;
  height: 60px;
  border-radius: 30px;
}
.button1Style{
  /* background:rgb(242,197,204); */
  background:rgb(255,174,191);
  border:1px solid rgb(242,197,204);
  font-size: 19px;
  width:150px;
  padding: 0px 5px;
  border-radius:6px;
}
.button2Style{
  background:rgb(182,182,182);
  border:1px solid rgb(182,182,182);
  font-size: 20px;
  width:150px;
  border-radius:6px;
}
.lineheight22{
  line-height: 24px;
}
.van-field__control:disabled {
    color: #999;
    cursor: not-allowed;
    opacity: 1;
    -webkit-text-fill-color: #c8c9cc;
}
</style>


