<template>
    <div class="res-container">
<!--index.wxml-->
       <div class="matchnum">
           <img class="mt20" src='@/assets/score.png' style="height:23px;"/>
           <div class="row-start-col-center">
            <div class="relposition">
                  <img class="mt20" :src="meimage" style="width:50px;height:50px;border-radius:25px" />
                  <div class="idnum absposition pinkcolor">No.{{meid}}</div>
              </div>
              <div class="relposition">
                <img class="mt20" src="@/assets/heart.png" style="height:36px;"/>
                <div class="score absposition">{{score}}</div>
              </div>
              <div class="relposition">
                  <img class="mt20" :src="otherimage" style="width:50px;height:50px;border-radius:25px" />
                  <div class="idnum absposition pinkcolor">No.{{otherid}}</div>
              </div>
            </div>
            <div class="row-start-col-center mt20">
              <img src='@/assets/same.png' style="width:50px;height:22px;margin-right:20px"/>
               <div v-for="(item,index) in sameNum" :key="index+10" class="inlineblock mt10">
                 <img src='@/assets/progress.png' style="width:20px;height:20px;"/>
               </div>
               <div v-for="(item,index) in diffNum" :key="index+20" class="inlineblock mt10">
                 <img src='@/assets/progress2.png' style="width:20px;height:20px;"/>
               </div>
            </div>
            <div class="row-start-col-center">
              <img src='@/assets/diff.png' style="width:50px;height:22px;margin-right:20px"/>
               <div v-for="(item,index) in diffNum" :key="index+30" class="inlineblock mt10">
                 <img src='@/assets/progress.png' style="width:20px;height:20px;"/>
               </div>
               <div v-for="(item,index) in sameNum" :key="index+40" class="inlineblock mt10">
                 <img src='@/assets/progress2.png' style="width:20px;height:20px;"/>
               </div>
            </div>
            <div class="pinkcolor fs16"  style="margin:16px 40px 8px 40px">
               {{same.words}}
            </div>
       </div>
       <div class="model">
         <div class="row-start">
            <div class="title fontbold fontTitle ml20">{{titleList[0]}}
            </div>
            <div class="fontTitle fs18 mt10 ml45">{{char.join}}</div>
         </div>
         <div class="wordMargin2">
             {{char.words}}
         </div>
       </div>
       <div class="predict">
         <div class="row-start">
           <div class="title fontbold fontTitle ml20">{{titleList[1]}}</div>
         </div>
        <div class="wordMargin2">
           <div class="text">
              {{wordsBetter(demand['他的虚假需求'],demand['他的真实需求'],demand['我的虚假需求'],demand['我的真实需求'])}}
             <div>
               {{time_words}}
             </div>
             <div>
              {{words}}
             </div>
           </div>
         </div>
       </div>
  </div>

</template>
<script>
import wxlogin from 'vue-wxlogin';
import {postAction} from '@/api/manage'
export default {
  components: { wxlogin },
  name: 'loveMatchRes',
  data() {
    return {
    openid:'',
    score:0,
    char:{},
    demand:{},
    same:{},
    time_words: "",
    words: "",
    meid:2,
    otherid:6806,
    sameNum: 0,
    diffNum: 0,
    meimage:'',
    otherimage:'',
    titleList:[]
    }
  },
  computed:{
    otherId(){
      return this.$route.query.otherId ? this.$route.query.otherId : ''
    },
    meId() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.$store.getters.getmeId;
    //   return '123';
    },
  },
  mounted(){
      this.getdouble()
  },
  methods: {
   getdouble(){
      const url = '/xcxlove/love/double'
      postAction(url, {me_id:this.meId, other_id:this.otherId}).then(res => {
        if (res.data.code == 200) {
          if(res.data.msg.same.score === '75') {
            this.sameNum = 8
            this.diffNum = 2
          } else if(res.data.msg.same.score === '50'){
            this.sameNum = 5
            this.diffNum = 5
          } else {
            this.sameNum = 2
            this.diffNum = 8
          }
            this.titleList =res.data.msg.title,
            this.char =res.data.msg.char, 
            this.demand =res.data.msg.demand.demand, 
            this.time_words =res.data.msg.demand.time_words, 
            this.words =res.data.msg.demand.words, 
            this.same =res.data.msg.same, 
            this.score =res.data.msg.total,
            this.meimage =res.data.msg.meimage,
            this.otherimage =res.data.msg.otherimage,
            this.meid =res.data.msg.meid,
            this.otherid =res.data.msg.otherid
        } 
    })
  },
   wordsBetter(ta1,ta2,me1,me2){
     let part1 = ''
     let part2 = ''
     let words = ''
     if(ta1 === ta2){
       part1 = ta1 + '是他选择你并使感情长久发展的原因，'
     }else {
       part1 = `你让TA一见钟情的点是${ta1}, 相处中TA更看重你的${ta2}。`
     }
     if(me1 === me2){
       part2 = me1 + '是你选择他并使感情长久发展的原因，'
     } else {
       part2 = `TA让你一见钟情的点是${me1}, 相处中你更看重他的${me2}。`
     }
     words = part1 + part2

     return words
   }
 }
}
</script>
<style scoped>
.res-container{
 height: 720px;
  display: flex;
  flex-direction: column;
  /* align-items: center;
  justify-content: flex-start; */
  box-sizing: border-box;
  background-image:url('http://www.miaoxingjundd.com/matchb.png');
  background-repeat:no-repeat;
  background-size: 100% 100%;
  position: relative;
  font-family: 'xinghan';
  text-align: left;
  line-height: 22px;
}
.matchnum{
  margin: 40px 10px 0px 10px;

  /* width: 640px; */
  min-height: 260px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-image:url('http://www.miaoxingjundd.com/bg1.png');
  background-repeat:no-repeat;
  background-size: 100% 100%;
}
.model{
  margin: 10px 10px 10px 10px;
  padding-bottom: 20px;
  min-height: 90px;
  background-image:url('http://www.miaoxingjundd.com/bg2.png');
  background-repeat:no-repeat;
  background-size: 100% 100%;
}
.predict{
  margin: 0px 10px 0px 10px;
  min-height: 100px;
  padding-bottom: 20px;
  background-image:url('http://www.miaoxingjundd.com/bg2.png');
  background-repeat:no-repeat;
  background-size: 100% 100%;
}
.fontTitle{
  color:#3a221d;
}
.score{
  top: 30px;
  left: 78px;
  color: #fff;
}
.wordMargin{
  margin:16px 24px 0px 24px
}
.wordMargin2{
  margin:16px 24px 0px 24px;
  color:#333
}
.text{
  font-size: 15px;
}
</style>