<template>
  <div style="padding:0px 20px; background:#fff">
      <div class="headercenter">
          <van-button @click="$router.go(-1)" plain round size="mini" icon="arrow-left" type="info" style="margin-right:20px"></van-button>
          <h2>记账程序</h2>
      </div>
      <div style="text-align:left;margin-bottom:10px">
          <van-button v-if="auth >=7" icon="plus" round size="small" type="info" @click="addAccount">新建记账</van-button>      </div>
      <van-popup v-model="show" position="bottom" :style="{ height: '50%',padding:'10px' }" >
          <van-form @submit="onSubmit">
            <van-field
              v-model="subId"
              name="客户ID"
              label="客户ID"
              placeholder="客户ID"
            />
            <van-field
              v-if="!this.subId"
              v-model="username"
              name="客户名称"
              label="客户名称"
              placeholder="客户名称"
              :rules="[{ required: true, message: '请填写客户名称' }]"
            />
            <van-field
              readonly
              clickable
              name="picker"
              :value="inname"
              label="账号"
              placeholder="点击选择收入账号"
              @click="showPickerI = true"
            />
            <van-popup v-model="showPickerI" position="bottom">
              <van-picker
                show-toolbar
                :columns="acountlist"
                @confirm="onConfirmnameI"
                @cancel="showPickerI = false"
              />
            </van-popup>
            <van-field
              readonly
              clickable
              name="picker"
              :value="product"
              label="项目"
              placeholder="点击选择收费项目"
              @click="showPickerP = true"
            />
            <van-popup v-model="showPickerP" position="bottom">
              <van-picker
                show-toolbar
                :columns="productList"
                @confirm="onConfirmnameP"
                @cancel="showPickerP = false"
              />
            </van-popup>
            <van-field
              v-model="price"
              name="总价"
              label="总价"
              placeholder="总价"
              :rules="[{ required: true, message: '请填写客户总价' }]"
            />
            <van-field
              readonly
              clickable
              name="picker"
              :value="outname"
              label="后端成员"
              placeholder="点击选择后端成员"
              @click="showPickerO = true"
            />
            <van-popup v-model="showPickerO" position="bottom">
              <van-picker
                show-toolbar
                :columns="backList"
                @confirm="onConfirmnameO"
                @cancel="showPickerO = false"
              />
            </van-popup>
            <van-field
              v-model="cost"
              name="后端价格"
              label="后端价格"
              placeholder="后端价格"
              :rules="[{ required: true, message: '请填写后端价格' }]"
            />
          <div style="margin: 16px;">
              <van-button round size="small" block type="info" :loading="loading" native-type="submit">提交</van-button>
          </div>
          </van-form>
      </van-popup>
      <div class="row-start mt20">
          <el-date-picker
          style="width:120px"
          v-model="selectMonth"
          @change="selectDate"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="month"
          placeholder="选择日期">
        </el-date-picker>
        <span class="pt10 pr20"> 总条数：{{list.length}} </span>
        <span class="pt10 pr20"> 总收入：{{inputSum}} 元</span>
        <span class="pt10 pr20"> 总支出：{{outputSum}} 元</span>
        <span class="pt10 pr10"> {{clickname1}}：</span>
		<span class="pt10 pr20"> 总条数：{{perLength}} </span>
        <span class="pt10 pr20"> 收入：{{priceSum}} 元</span>
        <span class="pt10"> 支出：{{backSum}} 元</span>
      </div>
      <el-table
        class="mt20"
        :data="list"
        element-loading-text="Loading"
        border
        fit
        highlight-current-row
        :header-cell-style="{ background: '#f8f8f8' }"
      >
        <el-table-column align="center" label="序列" min-width="80">
          <template slot-scope="scope">
            <!-- <el-button type="text" @click="getUserDetail(scope.row.Id)">{{ scope.$index + 1}}</el-button> -->
            {{ scope.$index + 1}}
          </template>
        </el-table-column>
        <el-table-column label="客户ID" min-width="80" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.subId }}</span>
          </template>
        </el-table-column>
		<el-table-column label="客户名字" min-width="80" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.client }}</span>
          </template>
        </el-table-column>
        <el-table-column label="收入账号" min-width="100" align="center">
          <template slot-scope="scope">
                <el-button @click="handleSum(scope.row.input,1)" type="text" size="small"> {{ scope.row.input }}</el-button>
          </template>
        </el-table-column>
        <el-table-column label="收费项目" min-width="100" align="center">
          <template slot-scope="scope">
            {{ scope.row.product }}
          </template>
        </el-table-column>
        <el-table-column label="总价" min-width="120" align="center">
          <template slot-scope="scope">
               {{ scope.row.price }}
          </template>
        </el-table-column>
        <el-table-column label="后端成员" min-width="100" align="center">
          <template slot-scope="scope">
                <el-button @click="handleSum(scope.row.back,2)" type="text" size="small"> {{ scope.row.back }}</el-button>
          </template>
        </el-table-column>
        <el-table-column label="后端价格" min-width="120" align="center">
          <template slot-scope="scope">
            {{ scope.row.cost }}
          </template>
        </el-table-column>
        <el-table-column label="提交时间" min-width="160" align="center">
          <template slot-scope="scope">
            {{ scope.row.date }}
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          width="100">
          <template slot-scope="scope">
            <el-button @click="handleEdit(scope.row)" type="text" size="small">编辑</el-button>
            <el-button @click="handleDelete(scope.row.Id)" type="text" size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- <el-dialog title="用户详情" :visible.sync="dialogVisible" width="750px">
      <el-row style="margin-left:50px" @submit="onSubmit">
        <el-col :md="8" :sm="12">
          <div class="content-wrap">
            <div class="contentTitle">客户ID</div>
            <div class="content">{{ row.subId }}</div>
          </div>
        </el-col>
        <el-col :md="8" :sm="12">
          <div class="content-wrap">
            <div class="contentTitle">填表人</div>
            <div class="content">{{ row.name }}</div>
          </div>
        </el-col>
        <el-col :md="8" :sm="12">
          <div class="content-wrap">
            <div class="contentTitle">后端成员</div>
            <div class="content">{{ row.back_name }}</div>
          </div>
        </el-col>
        <el-col :md="8" :sm="12">
          <div class="content-wrap">
            <div class="contentTitle">总价</div>
            <div class="content">{{ row.price }}</div>
          </div>
        </el-col>
        <el-col :md="8" :sm="12">
          <div class="content-wrap">
            <div class="contentTitle">前端价格</div>
            <div class="content">{{ row.front }}</div>
          </div>
        </el-col>
        <el-col :md="8" :sm="12">
          <div class="content-wrap">
            <div class="contentTitle">后端价格</div>
            <div class="content">{{ row.back1 }}</div>
          </div>
        </el-col>
        <el-col :md="8" :sm="12">
          <div class="content-wrap">
            <div class="contentTitle">提交时间</div>
            <div class="content">{{ row.time }}</div>
          </div>
        </el-col>
        </el-row>
      </el-dialog> -->
  </div>
</template>

<script>
import { postAction, getAction } from '@/api/manage'
export default {
  name: 'Login',
  data() {
    return {
      list: [],
      loading: false,
      show: false,
      columns: [],
      acountlist:[],
      backList:[],
      productList:[],
      showPickerI: false,
      showPickerO: false,
      showPickerP: false,
      username:'',
	    subId:'',
      Id:'',
      inname:'',
      outname:'',
      product:'',
      price:'',
      cost:'',
      list: [
      ],
      listLoading: false,
      dialogVisible:false,
      activeName:'first',
      form:{},
      selectMonth:'',
      tableList:[],
      submitorList:[],
      inputFlag:false,
      input:'',
      type:'add',
      priceSum:'',
      backSum:'',
      inputSum:'',
	  perLength:'',
      outputSum:'',
      clickname1:'',
      clickname2:'',
      month:''
    }
  },
  mounted() {
    this.fetchData()
    // this.getsubmitor()
    this.getInfo()
    const currday = new Date()
    const year = currday.getFullYear()
    const month = currday.getMonth() + 1
    this.month = month
    const day = '1'
    this.selectMonth = new Date(`${year}-${month}-${day}`)
 },
  computed:{
    auth(){
        return this.$route.query.auth
    }
  },
  methods: {
    submitInfo(index){
      this.tableList[index].edit = !this.tableList[index].edit
      // this.inputFlag = !this.inputFlag
    },
    selectDate(val){
     console.log('val',val)
     const list = val.split(' ')
     const month = Number(list[0].split('-')[1])
     const year = Number(list[0].split('-')[0])
      this.listLoading = true
      const url = '/oa/acdetail'
      getAction(url).then( res => {
        if( res.data.code == 200) {
         this.list = res.data.msg
         this.list = this.list.filter(item => item.month === month &&  item.year === year)
         this.inputSum = 0
         this.outputSum = 0
         this.list.forEach( item => { this.inputSum = Number(item.price) + this.inputSum} )
         this.list.forEach( item => { this.outputSum = Number(item.cost) + this.outputSum})
         this.listLoading = false
        }
      })
    },
    addAccount(){
      this.show = true
      this.type = 'add'
	     this.Id = ''
       this.subId = ''
       this.username = ''
       this.inname = ''
       this.outname=''
       this.product=''
       this.cost=''
       this.price=''
    },

    onConfirmnameI(value) {
      this.inname = value;
      this.showPickerI = false;
    },
    onConfirmnameO(value) {
      this.outname = value;
      this.showPickerO = false;
    },
    onConfirmnameP(value) {
      this.product = value;
      this.showPickerP = false;
    },
    onSubmit(){
        const url1 ='/oa/acnew'
        const url2 ='/oa/account/revise'
        const params = {Id: this.Id, subId: this.subId, username : this.username,inname: this.inname,outname:this.outname,product:this.product, cost:this.cost,price:this.price}
        this.loading = true
		    console.log('this.type',this.type)
        if(this.type === 'add'){
        postAction(url1, params).then( res => {
        this.loading = false
            if( res.data.code == 200) {
              this.$message.success(res.data.msg)
              this.show = false
              this.fetchData()
            }
            else{
              this.$message.warning(res.data.msg)
            }
        }).catch(() => {
            this.show = false
            this.loading = false
        })
        } else if(this.type = 'edit'){
          postAction( url2, params).then( res => {
          this.loading = false
              if( res.data.code == 200) {
                this.$message.success(res.data.msg)
                this.show = false
                this.fetchData()
              }
              else{
                this.$message.warning(res.data.msg)
              }
              }).catch(() => {
                  this.show = false
                  this.loading = false
              })
        }
    },
    getInfo(){
      const url = '/oa/account/getinfo'
      postAction(url).then( res => {
        if( res.data.code == 200) {
          this.acountlist = res.data.msg.input,
          this.backList = res.data.msg.out,
          this.productList = res.data.msg.prod
        }
      })
    },
    getsubmitor(){
      getAction('/admin/userlist').then( res => {
         this.submitorList = res.data
         this.tableList =  this.submitorList.filter(item => new Date(item.calender).getTime() > new Date(this.selectMonth).getTime())
         this.tableList = this.tableList.map(v => {
              this.$set(v, 'edit', true) // https://vuejs.org/v2/guide/reactivity.html
              return v
         })
         this.list = [...this.tableList]
         console.log('this.tableList',this.tableList)
      })
    },
    fetchData(){
      this.listLoading = true
      const url = '/oa/acdetail'
      getAction(url).then( res => {
        if( res.data.code == 200) {
         this.list = res.data.msg
         this.list = this.list.filter(item => item.month === this.month)
         this.inputSum = 0
         this.outputSum = 0
         this.list.forEach( item => { this.inputSum = Number(item.price) + this.inputSum} )
         this.list.forEach( item => { this.outputSum = Number(item.cost) + this.outputSum})
         this.listLoading = false
        }
      })
    },
    getUserDetail(id){
      this.dialogVisible = true
      let url = ''
      getAction(url).then( res => {
        this.form = res.data
        console.log(res)
      })
    },
    handleEdit(row){
       this.show = true
       this.type = 'edit'
	     this.Id = row.Id
       this.subId = row.subId
       this.username = row.client
       this.inname = row.input
       this.outname=row.back
       this.product=row.product
       this.cost=row.cost
       this.price=row.price
    },
    handleDelete(id){
      let url = '/oa/acdel'
      postAction(url,{Id:id}).then( res => {
       if(res.data.code == 200){
        this.$message.success(res.data.msg)
        this.fetchData()
       } else{
         this.$message.warning(res.data.msg)
       }
      })
    },
    handleSum(val,num){
      let url = '/oa/account/sum'
      this.priceSum = 0
      this.backSum = 0
	  this.perLength = 0
	  this.clickname1 = val
      if(num === 1){

        this.list.forEach(item => {
          if(item.input === val){
             this.priceSum = Number(this.priceSum) + Number(item.price)
             this.backSum = Number(this.backSum) + Number(item.cost)
			 this.perLength = Number(this.perLength) + 1
          }
        })
      }else {
        this.list.forEach(item => {
          if(item.back === val){
             this.priceSum = Number(this.priceSum) + Number(item.cost)
			 this.perLength = Number(this.perLength) + 1
          }
        })
      }

      // postAction(url,{name:val}).then( res => {
      //  if(res.data.code == 200){
      //     this.priceSum = res.data.msg.price,
      //     this.backSum = res.data.msg.cost
      //  } else{
      //    this.$message.warning(res.data.msg)
      //  }
      // })
    }
  }
}
</script>
<style scoped>
.login-container{
  background: #fff;
}
.headercenter{
    display:flex;
    justify-content: center;
    align-items: center;
}
</style>

