<template>
  <div class="container">
     <img src="../assets/startLogo1.png" style="width:100%;height:16px;margin-top:20px" alt="">
      <div class="user-info">
        <!-- <div>
          <img src="../assets/startLogo.jpg"/>
        </div> -->
        <div>
          <div class="img-head">
              <img :src="headimg" class="logoStyle" alt="">
          </div>
           <div style="color:#fff;margin-top:6px">
             {{name}}
                <i v-if="careerRes.sex === '男'" class="iconfont icon-nansheng1">&nbsp;</i>
                <i v-else class="iconfont icon-nvsheng1">&nbsp;</i>
           </div>
        </div>
        <div class="fixed" >
            <van-button class="buttonColor2" round block @click="gotoPay" ><i class="el-icon-unlock"/> 解锁全部:
              <!-- <span>￥79</span><span style="color:#666666; font-size: 20px; font-weight:400;margin-left:-18px;">X</span><span style="margin-left:10px">￥59</span></van-button> -->
              <span>￥68</span></van-button>
        </div>
        <div class="user-name">
           <div class="mt6">{{careerRes.city}} &nbsp; </div>
           <div class="mt6">{{changeTime(careerRes.birthday)}}</div>
           <!-- <div class="mt6">{{careerRes.bazi}} </div> -->
           <!-- <div class="mt6">{{careerRes.city}} &nbsp;用神：{{careerRes.five_elements}} </div> -->
        </div>
      </div>
      <div style="text-align:left;margin-top:30px">
        <div class="mt10">
            <!-- 职业发展综合评分 -->
            <img src="../assets/1.png" class="tab" alt="">
            <div >
                <div class="score">
                   <div><el-progress type="circle" :stroke-width="16" :percentage="Number(result.score)" status="success" :show-text="false" style="height:100px"></el-progress></div>
                   <div style="filter: blur(4px);color:rgb(19,206,102);font-size:28px;position:absolute">{{ result.score}} 分 </div>
                </div>
            </div>
        </div>
        <div class="mt20">
          <!-- 你命格中所显露的特质 -->
            <img src="../assets/2.png" class="tab" alt="">
            <div class="content-box" style="min-height:200px">
                <div class="text">
                  <div>{{careerRes.zhu_char}}</div>
                  <div><div><img src="../assets/divide.png" style="width:100px;height:10px;" alt=""></div></div>
                  <div class="decs fs15">显露的特质是你在别人眼中所显露的特质或者自己对于自己的认识，同时也是决定你在对待人和事时的心态和性格。所有显露的特质是相辅相成的，好坏之分因事而异。显露的特质是一个人无法更改的，且是从儿时起形成的。</div>
                </div>
                <div class="corner">
                  <img src="../assets/corner.png" style="height:50px" alt="">
                </div>
            </div>
        </div>
         <!-- 待挖掘的特质 -->
        <div class="mt20">
            <img src="../assets/3.png" class="tab" alt="">
            <div class="content-box" style="min-height:200px">
                <div class="text">
                  {{careerRes.fu_char}}
                  <div><div><img src="../assets/divide.png" style="width:100px;height:10px;" alt=""></div></div>
                  <div class="decs fs15">待挖掘的特质是你在成长的过程中不断形成的特质，而这种特质在成长与职业发展中会对人起到正向作用。这种特质很难被自己或者别人所察觉，但却是在对待人和事中的“杀手锏”，往往会体现在处理一件事情的细节中。在职场中我们需要尽量挖掘自己的此特质。</div>
                </div>
                <div class="corner">
                  <img src="../assets/corner.png" style="height:50px" alt="">
                </div>
            </div>
        </div>
        <div class="mt20">
           <!-- 适合工作 -->
            <img src="../assets/8.png" class="tab" alt="">
            <div class="content-box">
                <div class="text">
                  <div style="filter: blur(4px);">{{result.job}}</div>
                  <div><img src="../assets/divide.png" style="width:100px;height:10px;" alt=""></div>
                  <div class="decs fs15">这个方向是由“显露的特质”和“需挖掘的特质”共同决定而成，指向性明确。选择自己适合的职业方向和职位是职场发展的根本。</div>
                </div>
                <div class="corner">
                  <img src="../assets/corner.png" style="height:50px" alt="">
                </div>
            </div>
        </div>
        <div class="mt20">
          <!-- 应该规避的方面 -->
            <img src="../assets/4.png" class="tab" alt="">
            <div class="content-box">
                <div class="text">
                  <div style="filter: blur(4px);">{{result.noPerformance}}</div>
                  <div><img src="../assets/divide.png" style="width:100px;height:10px;" alt=""></div>
                  <div class="decs fs15">职业发展中应规避的方面是以一个人的职业发展中呈现阻碍作用的特质为基础。避免在职业发展中触碰自己的短板，规避短板特质所带来的问题。针对此类特质，提出规避建议。因为每个人不只有一个缺点，所以刷新报告后，规避的内容会有变化。</div>
                </div>
                <div class="corner">
                  <img src="../assets/corner.png" style="height:50px" alt="">
                </div>
            </div>
        </div>
         <!-- 自我决策 -->
         <div class="mt20">
            <img src="../assets/17.png" class="tab" alt="">
            <div class="content-box">
                <div class="text">
                  <img src="../assets/divide.png" style="width:100px;height:10px;" alt="">
                  <div class="decs fs15">
                    基本心理需求是行为背后的核心动力，包含：<br/>
                    <strong>自主性：</strong>源于内在意愿，而非外界强制。<br/>
                    <strong>胜任力：</strong>自信能成功完成任务，并从中获得成就感。<br/>
                    <strong>归属感：</strong>在人际交往中感受到接纳与支持。<br/>
                    自主性动机需要：尊重选择，鼓励内在兴趣。<br/>
                    胜任性动机需要：适当挑战，技能提升。<br/>
                    归属性动机需要：和谐氛围，真诚交流。<br/>
                  </div>
                </div>
                <div class="corner">
                  <img src="../assets/corner.png" style="height:50px" alt="">
                </div>
            </div>
        </div>
        <!-- 动机原理 -->
        <div class="mt20">
          <img src="../assets/16.png" class="tab" alt="">
          <div class="content-box">
              <div class="text">
                <img src="../assets/divide.png" style="width:100px;height:10px;" alt="">
                <div class="decs fs15">
                  动机占比就是外界对你行为的影响程度<br/>
                  <strong>内在动机</strong>：因为喜欢而做，最自由也最满足。<br/>
                  <strong>整合性动机：</strong>结合内外要求，行为较自主。<br/>
                  <strong>认同性动机：</strong>虽不直接喜欢，但认同其价值。<br/>
                  <strong>内化动机：</strong>将外在要求转化为内心责任。<br/>
                  <strong>外部调控动机：</strong>完全受奖励或惩罚驱动。<br/>
                </div>
              </div>
              <div class="corner">
                <img src="../assets/corner.png" style="height:50px" alt="">
              </div>
          </div>
        </div>
        <div class="mt20">
          <!-- DICS -->
            <img src="../assets/12.png" class="tab" alt="">
            <div class="content-box">
                <div class="text">
                  <div style="filter: blur(4px);">Dominance</div>
                  <img src="../assets/divide.png" style="width:100px;height:10px;" alt="">
                  <div class="decs fs15">
                    Dominance——支配型。适合角色：老板型/指挥者<br/>
                    Influence   ——影响型。适合角色：互动型/社交者<br/>
                    Steadiness ——稳健型。适合角色：支持型/支持者<br/>
                    Compliance——谨慎型。适合角色：修正型/思考者<br/>
                  </div>
                </div>
                <div class="corner">
                  <img src="../assets/corner.png" style="height:50px" alt="">
                </div>
            </div>
        </div>
        <div class="mt20">
          <!-- 大五人格 -->
            <img src="../assets/11.png" class="tab" alt="">
            <div class="content-box">
              <div class="text">
                  <!-- <div id="five" style="width: 100%;height:300px;"></div> -->
                  <img src="../assets/divide.png" style="width:100px;height:10px;" alt="">
                  <div class="decs fs15 pb20">
                    <div><strong>开放性：</strong>具有想象、审美、情感丰富、求异、创造、智能等特质。</div>
                    <div><strong>责任心：</strong>显示胜任、公正、条理、尽职、成就、自律、谨慎、克制等特点。</div>
                    <div><strong>外倾性：</strong>表现出热情、社交、果断、活跃、冒险、乐观等特质。</div>
                    <div><strong>宜人性：</strong>具有信任、利他、直率、依从、谦虚、移情等特质。</div>
                    <div><strong>神经质性：</strong>难以平衡焦虑、敌对、压抑、自我意识、冲动、脆弱等情绪的特质，即不具有保持情绪稳定的能力。</div>
                  </div>
              </div>
                <div class="corner">
                  <img src="../assets/corner.png" style="height:50px" alt="">
                </div>
            </div>
        </div>
        <div class="mt20">
          <!-- 马斯洛需求满足建议 -->
            <img src="../assets/15.png" class="tab" alt="">
            <div class="content-box">
                <div class="text">
                  <div><img src="../assets/divide.png" style="width:100px;height:10px;" alt=""></div>
                  <div class="decs fs14">
                        <div>马斯洛需求理论代表了在环境满足下，个人需求的满足程度。</div>
                        <div>按照倒三角方向，越在上面，面积越大的是建议优先满足的部分，越靠下面，面积越小的为建议劣后满足部分。即在工作环境的选择中，建议优先满足上方需求，更有利于自身事业的发展。其中：</div>
                        <div>* 物质需要代表了工资、福利等能使得生活富足稳定的资源；</div>
                        <div>* 安全需要代表了工作的稳定程度；</div>
                        <div>* 社交需要代表了人际环境的复杂程度和给力程度；</div>
                        <div>* 得到尊重代表了在环境中被尊重的程度和工作名声被尊重的程度；</div>
                        <div>* 自我实现代表了工作是否是自己想要做的那类工作。</div>
                  </div>
                </div>
                <div class="corner">
                  <img src="../assets/corner.png" style="height:50px" alt="">
                </div>
            </div>
        </div>
        <div class="mt20">
          <!-- 工作曲线 -->
            <img src="../assets/5.png" class="tab" alt="">
            <div class="content-box">
              <div>
                  <div id="main" style="width: 100%;height:300px;"></div>
              </div>
                <div class="corner">
                  <img src="../assets/corner.png" style="height:50px" alt="">
                </div>
            </div>
        </div>
        <div class="mt20">
          <!-- 财运曲线 -->
            <img src="../assets/10.png" class="tab" alt="">
            <div class="content-box">
              <div>
                  <div id="money" style="width: 100%;height:300px;"></div>
              </div>
                <div class="corner">
                  <img src="../assets/corner.png" style="height:50px" alt="">
                </div>
            </div>
        </div>
        <div class="mt20">
          <!-- 升职 -->
            <img src="../assets/6.png" class="tab" alt="">
            <div class="content-box">
                <div class="text">
                  <div style="filter: blur(4px);">{{result.year}}</div>
                  <div><img src="../assets/divide.png" style="width:100px;height:10px;" alt=""></div>
                  <div class="decs fs15">这些年份，你应该在合适的职业或者职位上充分利用自己显露的特质中的优势部分，进而在职场中更进一步。</div>
                </div>
                <div class="corner">
                  <img src="../assets/corner.png" style="height:50px" alt="">
                </div>
            </div>
        </div>
        <!-- <div class="mt20">
            <img src="../assets/7.png" class="tab" alt="">
            <div class="content-box">
                <div class="text">
                   <div style="filter: blur(4px);">{{result.position}}</div>
                   <div><img src="../assets/divide.png" style="width:100px;height:10px;" alt=""></div>
                  <div class="decs fs15">方位代表着对你最有利的方位，这个方位可以应用在你办公室的布置中，即坐在办公室该方位有利于事业发展，在办公桌该方位摆放风水饰品有利于事业发展。</div>
                </div>
                <div class="corner">
                  <img src="../assets/corner.png" style="height:50px" alt="">
                </div>
            </div>
        </div> -->
        <!-- 应注意的点 -->
        <div class="mt20">
            <img src="../assets/9.png" class="tab" alt="">
            <div class="content-box">
                <div class="text">
                   <div style="filter: blur(4px);">{{careerRes.motto}}</div>
                </div>
                <div class="corner">
                  <img src="../assets/corner.png" style="height:50px" alt="">
                </div>
            </div>
        </div>
      </div>

      <div class="mt20">
        <img src="../assets/endlogo.png" style="width:100%"  alt="">
      </div>
  </div>
</template>
<script>
import axios from 'axios'
import echarts from '../echarts/index'
import {postAction,getAction } from '@/api/manage'
import wx from 'weixin-jsapi'
export default {
  name: 'success',
  data() {
    return {
      userName:sessionStorage.getItem("us_name"),
      headImg:sessionStorage.getItem("us_headimgurl"),
      time:'2021年9月10日10时',
      fiveLeader:'火',
      city:'天津',
      sex:'男',
      result:{
         score:60,
         mainPerformance:'你是个目标感很强的人，办事讲求效率。同时你渴望成就，以自己的成功为荣，为了成功即使牺牲自己的个人生活也在所不惜，你会以一个人取得的成就和相应的社会地位来衡量这个人，掌声、鲜花、金钱、地位，这些和人的价值捆绑在一起，拥有了这些，对你而言也就体现了自己的价值，就拥有了快乐.',
         digPerformance:'提升自己用逻辑来分析事物的能力，而且要挖掘自己对于自己所掌握领域的知识深度，加深对于未知领域探索的渴望程度，力求将知识而非更多的经验运用到工作中。你需要经常的给自己一些私人空间，用作思考。在做事以前，理智而有条理的思考做事的大致流程，避免盲目的做事导致失败。',
         noPerformance:'气氛死板的团队和领导，严格遵循公司制度的工作，会阻碍你在职场上的发展。你不宜选择艺术类、表演类工作，也不宜选择将自己的爱好作为工作，或者说不宜因为生活上的事影响事业。所以在职场中，你不适合以自己爱好创业，也不适合选择一个比较沉闷的团队中工作。',
         year:'需要好好把握，易升职的年份是：2030，2020 你在这两个年份的职场发展中，会相比之前更加凸显自己显露的特质。',
         position:'你最有利的方位是西边，即坐在办公室该方位有利于事业发展，在办公桌该方位摆放风水饰品有利于事业发展',
         job:'更适合做一些目标比较明确的工作，比如项目管理、大客户管理、市场开发、营销、市场推广等。适合的工作为你提供了职业选择的部分方向.',
         attention:'如果你搞不定身边的小人，那么就把他能伤害到你的手段给封死。'
      },
      careerRes:{}
    }
  },
  created () {
  },
  computed:{
    userinfoCareer() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.$store.getters.getUserinfoCareer || {};
    },
    openid() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.userinfoCareer.us_openid;
    },
    headimg() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.userinfoCareer.us_headimgurl;
    },
    name() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.userinfoCareer.us_name;
    }
  },
  mounted(){
    this.getCareerResult()
    this.$nextTick(()=>{

    })
  },
  methods: {
    changeTime(time){
      if(time){
       const timeList = time.split('-')
       return `${timeList[0]}年${timeList[1]}月${timeList[2]}日${timeList[3]}时`
      } else{
        return time
      }
    },
    gotoPay(){
      const url = '/pr/pay/wxpay'
      var that = this
      getAction(url, {re_id: this.$route.query.re_id, openid: this.openid}).then( res => {
          if(res.data.code === 200){
            const result = res.data.msg.result
            wx.config({
              debug: false, // 这里一般在测试阶段先用ture，等打包给后台的时候就改回false,
              appId: result.appId, // 必填，公众号的唯一标识
              timestamp: result.timeStamp, // 必填，生成签名的时间戳
              nonceStr: result.nonceStr, // 必填，生成签名的随机串
              signature: result.paySign, // 必填，签名
              jsApiList: ['checkJsApi','chooseWXPay'] // 必填，需要使用的JS接口列表
            })
            wx.ready(() => {
              wx.checkJsApi({
                jsApiList: ['chooseWXPay'],
                success:function(res){
                  console.log("success")
                  wx.chooseWXPay({
                      timestamp: result.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
                      nonceStr: result.nonceStr, // 支付签名随机串，不长于 32 位
                      package: result.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
                      signType: result.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
                      paySign: result.paySign, // 支付签名
                      success: function (res) {  // 支付成功后的回调函数
                        alert("支付成功");
                        window.location.href = "https://online.miaoxingjundd.com/careerResult?re_id=" + that.$route.query.re_id + "&openid=" + sessionStorage.getItem("us_openid")
                        // that.$router.push({path:'/careerResult', query:{re_id:this.$route.query.re_id, openid:sessionStorage.getItem("us_openid")}})
                      },
                      fail: function (res) {
                        alert("支付失败");
                      }
                    })
                },
                fail:function(res){
                  console.log("fail");
                  console.log(res) }
              })
            })
            wx.error(err => {
              Toast.fail(err)
            })
          }else{
            Toast.fail(res.data.msg);
          }
      })
    },
    drawLine1 (dataList,text, color) {
      var myChart = echarts.init(document.getElementById('main'));
      // 指定图表的配置项和数据
      var option = {
      title: {
              text: '2021-2030年 工作运势曲线',
              //标题样式
              textStyle: {
                  fontWeight: 'normal',
                  color: '#fff',
              },
              left: 50,
          },
        color:['#3ba272'],
        xAxis: {
          type: 'category',
          data: ['21', '22', '23', '24', '25', '26', '27', '28', '29', '30'],
          axisLine: {
              lineStyle: {
                  color: '#fff'
              }
          },
        },
        yAxis: {
          type: 'value',
          axisLine: {
              lineStyle: {
                  color: '#fff'
              }
          },
        },
        series: [
          {
            data: [3, 5, 4, 1, 4, 5, 1,2,2,5],
            type: 'line',
            smooth: true
          }
        ]
      };

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(this.setOptionData(dataList,text, color));
    },
    drawLine2 (dataList,text, color) {
      var myChart = echarts.init(document.getElementById('money'));
      // 指定图表的配置项和数据
      var option =option = {
      title: {
              text: '2021-2030年 财富运势曲线',
              //标题样式
              textStyle: {
                  fontWeight: 'normal',
                  color: '#fff',
              },
              left: 50,
          },
        color:['#ee6666', '#3ba272'],
        xAxis: {
          type: 'category',
          data: ['21', '22', '23', '24', '25', '26', '27', '28', '29', '30'],
          axisLine: {
              lineStyle: {
                  color: '#fff'
              }
          },
        },
        yAxis: {
          type: 'value',
          axisLine: {
              lineStyle: {
                  color: '#fff'
              }
          },
        },
        series: [
          {
            data: [3, 5, 4, 1, 4, 5, 1,2,2,5],
            type: 'line',
            smooth: true
          }
        ]
      };

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(this.setOptionData(dataList,text, color));
    },
    setOptionData(dataList,text, color){
     var option = {
      title: {
              text: text,
              //标题样式
              textStyle: {
                  fontWeight: 'normal',
                  color: '#fff',
              },
              left: 50,
          },
        color:[color],
        xAxis: {
          type: 'category',
          data: ['21', '22', '23', '24', '25', '26', '27', '28', '29', '30'],
          axisLine: {
              lineStyle: {
                  color: '#fff'
              }
          },
        },
        yAxis: {
          type: 'value',
          axisLine: {
              lineStyle: {
                  color: '#fff'
              }
          },
        },
        series: [
          {
            data: dataList,
            type: 'line',
            smooth: true
          }
        ]
      };
      return option
    },
    doOption(keyList, valueList){
      let option = {
          backgroundColor: '#161627',
          title: {
              text: '大五人格测试结果',
              left: 'center',
              textStyle: {
                  color: '#F9713C'
              }
          },
          legend: {
              bottom: 5,
              data: [''],
              itemGap: 20,
              textStyle: {
                  color: '#fff',
                  fontSize: 16
              },
              selectedMode: 'single'
          },
          radar: {
              indicator: keyList,
              shape: 'circle',
              splitNumber: 5,
              name: {
                  textStyle: {
                      color: 'rgb(238, 197, 102)'
                  }
              },
              splitLine: {
                  lineStyle: {
                      color: [
                          'rgba(238, 197, 102, 0.1)', 'rgba(238, 197, 102, 0.2)',
                          'rgba(238, 197, 102, 0.4)', 'rgba(238, 197, 102, 0.6)',
                          'rgba(238, 197, 102, 0.8)', 'rgba(238, 197, 102, 1)'
                      ].reverse()
                  }
              },

              splitArea: {
                  show: false
              },
              axisLine: {
                  lineStyle: {
                      color: 'rgba(238, 197, 102, 0.5)'
                  }
              }
          },
          series: [
              {
                  name: '北京',
                  type: 'radar',
                  lineStyle: {
                      normal: {
                          width: 3,
                          opacity: 0.5
                      }
                  },
                  data: [valueList],
                  symbol: 'none',
                  itemStyle: {
                      color: '#F9713C'
                  },
                  areaStyle: {
                      opacity: 0.2
                  }
              }
          ]
      }
      return option
    },
    drawLine (keyList, valueList) {
      var echarts = require('echarts');
      var myChart = echarts.init(document.getElementById('five'));
      myChart.setOption(this.doOption(keyList, valueList))
    },
    getCareerResult(){
      const url = '/pr/check_report'
      postAction(url, {openid:this.openid,re_id: this.$route.query.re_id}).then( res => {
        this.careerRes = res.data.data
        const list = Object.values(this.careerRes.tenyear)
        const workList = list.map(item => item.印)
        const moneyList = list.map(item => item.财)
        this.drawLine1(workList,'2021-2030年 工作运势曲线', '#3ba272')
        this.drawLine2(moneyList,'2021-2030年 财富运势曲线','#ee6666')
        // let keyList =  Object.keys(this.careerRes.five)
        // const valueList =  Object.values(this.careerRes.five)
        // const max = Math.max(...valueList)
        // keyList = keyList.map(item => {return {name: item, max:max }} )
        // console.log('keyList',keyList)
        // console.log('valueList',valueList)
        // this.drawLine(keyList,valueList)
        // console.log(list,workList,moneyList)
      })
    }
  }
}
</script>
<style scoped>
.container{
 width: 100%;
 background-image: url('../assets/detailBg.png');
 padding-bottom: 30px;
 /* font-family: 'xinghan'; */
}
.user-info{
  display: flex;
  align-items: center;
  padding-top: 30px;
  padding-left: 30px;
}
.img-head{
  width:  80px;
  height: 80px;
  border: 2px solid white;
  border-radius: 40px;
}
.logoStyle{
  width: 80px;
  height: 80px;
  border-radius: 40px;
}
.user-name{
  color: white;
  text-align: left;
  margin-left: 20px;
  margin-top: -28px;
  padding: 0px 20px 20px 10px;
  background-image: url('../assets/startLogo2.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.score{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.text{
  color: #fff;
  padding: 20px 40px 30px 40px;
  line-height: 28px;
}
.mt6{
  margin-top: 6px;
}
.fs15{
  font-size: 15px;
}
.mt10{
  margin-top: 10px;
}
.mt20{
  margin-top: 20px;
}
.tab{
  height: 30px;
}
.decs{
  color: #ccc8c8;
  line-height: 28px;

}
.content-box{
  width: 95%;
  margin: 0px 20px 0px 6px;
  padding:30px 6px 12px 6px;
  background-image: url('../assets/box2.png');
  background-size: 98% 100%;
  background-repeat: no-repeat;
  position: relative;
}
.corner{
  position: absolute;
  bottom: -2px;
  right: 10px;
}
.buttonColor2{
  background: rgb(19,206,102);
  color: white;
  border: 2px solid rgb(19,206,102);
  height: 50px;
  font-size: 18px;
}
.fixed{
    position: fixed;
    cursor: pointer;
    top:85%;
    z-index: 99999;
    width: 80%;
    height: 50px;
    /* border-radius: 16px; */
    /* background:  linear-gradient(to right,  rgb(5,89,250) 0%,rgb(54,205,126));; */
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    padding-top: 16px;
}
</style>


