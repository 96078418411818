<template>
  <div class="container">
    <div class="formClass">
      <van-form >
        <van-field
          v-model="username"
          name="用户名"
          label="用户名"
          placeholder="用户名"
        />
        <van-field
           style="margin-top:20px"
            v-model="password"
            type="password"
            label="密码"
            placeholder="请输入密码"
        />
        <div style="margin: 20px 16px;">
          <van-button :loading="loading" loading-text="请求中..." style="background:rgb(46,211,121)" round block type="info" native-type="submit" @click="onSubmit">绑定账号</van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import { Toast } from 'vant';
import {postAction} from '@/api/manage'
export default {
  name: 'success',
  data() {
    return {
      username: '',
      password: '',
      loading:false
   };
  },
  computed:{
    userinfoCareer() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.$store.getters.getUserinfoCareer || {};
    },
    uid() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.userinfoCareer.us_id;
    },
    openid() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.userinfoCareer.us_openid;
    },
    unionid() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.userinfoCareer.us_unionid;
    },
  },
  methods:{
            onSubmit(){
              if(this.username == '' || this.password == ''){
                Toast.fail('输入不能为空');
                return
              }
                let url = '/pr/bind'
                const params = {
                    account:this.username,
                    pwd:this.password,
                    uid:this.uid,
                    openid:this.openid,
                    unionid:this.unionid
                }
                this.loading = true
                postAction(url,params)
                .then((res) => {
                   if(res.data.code === 201){
                     Toast.success(res.data.msg);
                     this.$router.push({ path: '/reportList'})
                   }else{
                     Toast.fail(res.data.msg);
                     this.$router.push({ path: '/reportList' })
                   }
                })
                .finally(res => {
                  this.loading = false
                })
            },
  }
}
</script>
<style scoped>
.container{
    width: 100%;
    height: 100vh;
    background-image: url('../assets/addBg.jpg');
    background-size: 99% 100%;
    background-repeat: no-repeat;
    color: white;
}
.formClass{
  width: 70%;
  margin: 0px auto;
  padding-top: 110px;
}
/deep/.van-field__label{
   width: 5.2em;
}
/deep/.van-cell{
  background: rgba(79,48,167, 0.8);
  border-radius: 10px;
}
/deep/.van-field__label{
  color: #f0f0f0; 
}
/deep/.van-radio__label{
  color: #f0f0f0; 
}
/deep/ input{
  color: #f0f0f0; 
  background-color: transparent;
}
.warningWord{
  text-align: right;
  color: rgb(46,211,120);
  font-size: 12px;
  padding-right: 10px;
}
.mt6{
  margin-top: 8px;
}
</style>


