<template>
 <div>
  <div class="match-container">
     <div class="mt40" v-if="nameList.length > 0">
         <div v-for="(item,index) in nameList" :key="index" @click="gotoRes(item.other_id)" class="record-item row-center">
             <div class="index">{{index + 1}}</div>
             <div class="col-start" style="width:70px">
                 <img :src="item.other_image" class="headImg"/>
                 <div>No.{{item.other_id}}</div>
             </div>
             <div>
                 <img src="@/assets/match.png" style="height:35px" />
             </div>
             <div class="col-start" style="width:70px">
                 <img :src="meImage" class="headImg"/>
                 <div>No.{{meId}}</div>
             </div>
         </div>
     </div>
     <div class="center" style="height:500px;padding:0px 100px" v-else><div>暂无匹配记录，请返回上一页末尾，点击分享好友。</div></div>
  </div>
    <!-- <div style="text-align:center;color:#666;margin-top:20px">
          <img src="../assets/kefu.jpg" style="width:30%"  alt="">
            <div style="font-size:14px;margin:20px">系统问题反馈请长按二维码，添加微信。</div>
    </div> -->
    <div style="text-align:center;color:#444;margin-top:20px">
          <img src="../assets/qrcode.jpg" style="width:30%"  alt="">
            <div style="font-size:14px;padding-top:10px">想了解更多爱情观理论，长按二维码关注：喵星君大大</div>
            <div style="font-size:14px;padding-top:6px;padding-bottom:20px">信息修改或系统问题反馈请前往<a style="color: #409EFF;" href="https://online.miaoxingjundd.com/home" >职业规划系统</a>列表页</div>
    </div>
 </div>


</template>
<script>
import wxlogin from 'vue-wxlogin';
import {postAction} from '@/api/manage'
export default {
  components: { wxlogin },
  name: 'loveMatchList',
  data() {
    return {
      nameList:[],
      meId:'',
      meImage:'',
    //   openid:'oPcgs43p0G--NccL1dlGhg3VFQqA'
    }
  },
  computed:{
    userinfoLove() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.$store.getters.getUserinfoLove || {};
    },
    // openid() {
    //   // 通过vuex的getters方法来获取state里面的数据
    //   return this.userinfoLove.jsopenid;
    // },
    unionid() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.userinfoLove.unionid;
    }
  },
  mounted(){
    this.matchRecord()
  },
  methods: {
    matchRecord(){
        const url = '/xcxlove/love/list'
        postAction(url, {unionid: this.unionid}).then(res => {
        //4.解密成功后 获取自己服务器返回的结果
            if (res.data.code == 200) {
                this.meId = res.data.msg.me.my_id,
                this.meImage = res.data.msg.me.my_image,
                this.nameList = res.data.msg.result
            }
        })
    },
    gotoRes(otherId){
      this.$router.push({path:'/loveThreeMatchRes',query:{otherId:otherId}})
    }
  }
}
</script>
<style scoped>
.match-container{
  min-height: 720px;
  display: flex;
  flex-direction: column;
  /* align-items: center;
  justify-content: flex-start; */
  box-sizing: border-box;
  background-image:url('http://www.miaoxingjundd.com/recordList.png');
  background-repeat:no-repeat;
  background-size: 100% 100%;
  position: relative;
  font-family: 'xinghan';
  text-align: left;
}
.record-item{
  cursor: pointer;
  box-sizing: border-box;
  align-items: center;
  text-align: left;
  margin: 10px 10px;
  min-height: 100px;
  color: rgb(3,0,0);
  font-size: 16px;
  background-image:url('http://www.miaoxingjundd.com/white.png');
  background-repeat:no-repeat;
  background-size: 100% 100%;
  /* font-weight: 600; */
}
.headImg{
    width:50px;
    height:50px;
    border-radius: 25px;
}
.index{
    font-size: 28px;
    color: rgb(243,174,176);
    margin-right: 8px;
}
</style>
