<template>
  <div>
    <div class="love-container" style="position: relative;">
      <div class="listIcon">
          <i class="iconfont icon-24gl-playlistHeart fs30" @click="gotoMatch" />
          <i class="iconfont icon-bianji iconSize ml10" @click="gotoEdit" />
      </div>
      <div v-if="!openid || gotoFlag == true" class='linkword' @click="gotowelcome" >获取你的爱情三元论报告和与TA的匹配指数，请点击登陆</div>
      <div :class="[gotoFlag == true ? 'filter' : '']">
        <div class="birthday">{{info.birthday}}</div>
        <div class="number">N0.{{meId}}</div>
        <img class="radarSize" :src="`http://www.miaoxingjundd.com/${char_num}.jpg`"/>
        <div class="col-start-start threeText">
          <!-- 激情 -->
          <div>
            <span style="font-weight:600;color:rgb(141,105,85)">激情 </span>
            <div v-for="(item,index) in passion" :key="index" style="display:inline-block">
                <img src='@/assets/progress.png' style="width:16px;height:16px"/>
            </div>
          <div >{{pass_words}}</div>
          </div>
            <!-- 亲密 -->
          <div class="mt10">
            <span style="font-weight:600;color:rgb(141,105,85)">亲密 </span>
            <div v-for="(item,index) in intimacy" :key="index" style="display:inline-block">
              <img src='@/assets/progress.png' style="width:16px;height:16px;"/>
            </div>
            <div>{{inti_words}}</div>
          </div>
          <!-- 承诺 -->
          <div class="mt10">
            <span style="font-weight:600;color:rgb(141,105,85)">承诺 </span>
            <div v-for="(item,index) in commitment" :key="index" style="display:inline-block">
              <img src='@/assets/progress.png' style="width:16px;height:16px;"/>
            </div>
          <div>{{comm_words}}</div>
          </div>
        </div>
        <!-- 爱情表现 -->
          <div class="char_words">
          <div class="title fontbold fontTitle ml10">{{titleList[0]}}</div>
          <div class="content"> {{char_words}}</div>
          </div>
          <!-- 爱情箴言 -->
          <div class="truth_words">
          <div class="title fontbold fontTitle ml10">{{titleList[1]}}</div>
          <div class="content"> {{truth_words}}</div>
          </div>
          <el-button v-if="loadingOver" class='sharebutton' @click="editShareFlag">分享好友，可查看你们匹配结果</el-button>
          <div class="shared" v-if="maskflag" @click="editShareFlag">
              <img  src='@/assets/share.jpg' class="shared"/>
              <!--div style="color:#fff">分享好友，看看你们感情有多深？</div>-->
          </div>
      </div>
    </div>
    <div style="text-align:center;color:#444;margin-top:40px">
          <img src="../assets/qrcode.jpg" style="width:30%"  alt="">
            <div style="font-size:14px;padding:20px">想了解更多，请关注公众号：喵星君大大</div>
    </div>
  </div>
</template>
<script>
import wxlogin from 'vue-wxlogin';
import { postAction, getAction } from '@/api/manage'
import wx from 'weixin-jsapi'
import { Toast } from 'vant';
export default {
  name: 'Login',
  data() {
    return {
        char_words:'我希望我喜欢你的那个点，不会因为你的老去和新鲜感的平淡而泯灭。',
        comm_words:'今朝有酒今朝醉，我要及时行乐',
        commitment:0,
        inti_words:'我不缺爱，我想要依靠',
        intimacy:0,
        pass_words:'我喜欢荷尔蒙飙升的浪漫',
        passion:0,
        truth_words:'恋爱就像拔河,都希望对方爱自己更多,拼命想要赢得比赛,但忘了如果对方先放手,自己只会摔得头破血流。',
        numArr:[],
        char_num:0,
        titleList:[],
        gotoFlag:true,
        showFlag:false,
        signObj:{},
        userInfo:{},
        maskflag: false,
        loadingOver:false,
        info:{},
    }
  },
  computed:{
    userinfoLove() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.$store.getters.getUserinfoLove || {};
    },
    openid() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.userinfoLove.jsopenid;
    },
    unionid() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.userinfoLove.unionid;
    },
    meId() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.$store.getters.getmeId;
    //   return '123';
    },
    otherId(){
      return this.$route.query.otherId ? this.$route.query.otherId : ''
    },
    code(){
      return this.$route.query.code ? this.$route.query.code : ''
    },
  },
  created() {
    this.initConfig()      
    wx.ready(()=>{
        this.configShareInfo()
    })
    wx.error(function(res){
        alert(res)
    });

  },
  mounted(){
    if(!this.unionid){
      if(!this.code){
        var url = encodeURIComponent(window.location.protocol+"//"+window.location.hostname+ '/loveThreeHome')
        window.location.href=`https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxbb2af162829dc7fe&redirect_uri=${url}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`
      }else {
         this.getJsLogin()
      }
    }else {
      console.log('有unionid')
      this.getLoveSingle() 
    } 
    if(this.otherId){
      this.$store.dispatch("setotherIdval", this.otherId);
    }
  },
  methods: {
   configShareInfo(meId) {
        wx.onMenuShareAppMessage({
            title: '查查你和TA的匹配度',
            desc: '快来看你与好友的匹配程度哦', 
            link: `https://online.miaoxingjundd.com/loveThreeHome?otherId=${meId?meId:this.meId}`,
            imgUrl: 'https://online.miaoxingjundd.com/images/matchRes.jpg',
            type: '',
            dataUrl: '', 
            success: function () {
                console.log("分享成功");
            },
            cancel: function () {
                console.log("取消分享");
            }
        });
   },
   initConfig(){
    const url = '/xcxlove/getsign'
    const conurl = window.location.href.split('#')[0]
    postAction(url, {url:conurl}).then(res => {
        if (res.data.code == 200) {
           this.signObj = res.data.msg
           console.log('this.signObj',this.signObj)
            wx.config({
              debug: process.env.NODE_ENV==='development', // 这里一般在测试阶段先用ture，等打包给后台的时候就改回false,
              appId: 'wxbb2af162829dc7fe', // 必填，公众号的唯一标识
              timestamp: this.signObj.time, // 必填，生成签名的时间戳
              nonceStr: this.signObj.nonce, // 必填，生成签名的随机串
              signature: this.signObj.sign, // 必填，签名
              jsApiList: ['onMenuShareAppMessage'],
            })
        }
    })
   },
   getJsLogin(){
      const url = '/user/jslogin'
      // const url = '/pr/test'
          postAction(url, {code:this.code}).then( res => {
            if(res.data.code == 200){
                this.userInfo = res.data.msg
                // this.$store.dispatch("setuidval", this.userInfo.us_id);
                // this.$store.dispatch("setopenidval", this.userInfo.jsopenid);
                // this.$store.dispatch("setunionidval", this.userInfo.unionid);
                // this.$store.dispatch("setheadimgval", this.userInfo.image);
                // this.$store.dispatch("setnameval", this.userInfo.nickname);
                this.$store.commit('setUserInfoLove', this.userInfo)
                this.getLoveSingle() 
                console.log('this.userInfo',this.userInfo,this.$store.getters)
            } else if(res.data.code == 400) {
                this.userInfo = res.data.msg
                this.$store.commit('setUserInfoLove', this.userInfo)
                // this.$store.dispatch("setuidval", this.userInfo.us_id);
                // this.$store.dispatch("setopenidval", this.userInfo.jsopenid);
                // this.$store.dispatch("setunionidval", this.userInfo.unionid);
                // this.$store.dispatch("setheadimgval", this.userInfo.image);
                // this.$store.dispatch("setnameval", this.userInfo.nickname);
                this.$router.push({path:'/loveThreeRegister'})
            }
          })
   },
   getdouble(){
    const url = '/xcxlove/love/double'
    postAction(url, {me_id:this.meId,other_id:this.otherId,unionid:this.unionid}).then(res => {
        if (res.data.code == 200) {
            // Toast.success('匹配成功')
        } 
    })
  },
  getLoveSingle(){
      console.log('该调接口了')
      const url = '/xcxlove/love/single'
      postAction(url, {openid:this.openid,unionid:this.unionid}).then(res => {
      // postAction(url, {openid:'test',unionid:'test'}).then(res => {
        //4.解密成功后 获取自己服务器返回的结果
        if (res.data.code == 200) {
            this.info = res.data.msg.info
            this.$store.dispatch("setmeIdval", this.info.Id);
            this.char_num = res.data.msg.char_num,
            this.titleList = res.data.msg.title,
            this.numArr =  [res.data.msg.passion,res.data.msg.intimacy,res.data.msg.commitment],
            this.char_words = res.data.msg.char_words, 
            this.comm_words = res.data.msg.comm_words,
            this.commitment = res.data.msg.commitment,
            this.inti_words = res.data.msg.inti_words,
            this.intimacy = res.data.msg.intimacy,
            this.pass_words = res.data.msg.pass_words,
            this.passion = res.data.msg.passion,
            this.truth_words = res.data.msg.truth_words,
            this.gotoFlag = false,
            this.loadingOver = true,
            this.showFlag = res.data.msg.show
            this.configShareInfo(this.info.Id)
          if(this.otherId){
            this.getdouble()
           }
        } else if(res.data.code == 400){
          this.gotoFlag = true
        }
      })
  },
  editShareFlag(){
    this.maskflag = !this.maskflag
    console.log('111',this.maskflag)
  },
  gotoMatch(){
    this.$router.push({path:'/loveThreeMatchList'})
  },
  gotoEdit(){
     this.$router.push({path:'/loveThreeRegister', query:{birthday:this.info.birthday, sex:this.info.sex, city:this.info.city}})
  },
  gotowelcome(){
      this.$router.push({path:'/loveThreeRegister'})
//    var btn = document.getElementById("launch-btn");
//     btn.addEventListener("launch", function (e) {
//       console.log("success");
//     });
//     btn.addEventListener("error", function (e) {
//       alert("小程序打开失败");
//       console.log("fail", e.detail);
//     });
   }
 }
}
</script>
<style scoped>
/**index.wxss**/
.radarSize{
  width: 259px;
  height:191px;
  margin-top: 100px;
}
.listIcon{
  position: absolute;
  top: 20px;
  right: 16px;
  cursor: pointer;
  /* box-shadow:3px 3px 3px #888888; */
  cursor: pointer;
}
.iconSize{
  font-size: 32px;
}
.sharebutton{
  font-size: 16px;
  background: rgba(255, 145, 124, 0.5);
  width: 100%;
  margin: 0px;
}
.number{
  position: absolute;
  top: 66px;
  left: 50px;
  color: rgb(243,174,176);
  font-size: 18px;
}
.birthday{
  position: absolute;
  top: 40px;
  left: 36%;
  color: rgb(243,174,176);
  font-size: 14px;
}
.threeText{
  color: rgb(243,174,176);
  font-size: 16px;
  text-align: left;
  margin-left: 80px;
}
.char_words{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  text-align: left;
  margin: 30px 30px 10px 30px;
  min-height: 120px;
  color: rgb(3,0,0);
  font-size: 16px;
  background-image:url('http://www.miaoxingjundd.com/bg2.png');
  background-repeat:no-repeat;
  background-size: 100% 100%;
  /* font-weight: 600; */
}
.truth_words{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  text-align: left;
  margin: 10px 30px 0px 30px;
  min-height: 120px;
  color: #030000;
  font-size: 16px;
  background-image:url('http://www.miaoxingjundd.com/bg2.png');
  background-repeat:no-repeat;
  background-size: 100% 100%;
  /* font-weight: 600; */
}

.content{
  padding: 10px 16px 0px 16px;
  color:#333
}
.love-container {
  height: 720px;
  display: flex;
  flex-direction: column;
  /* align-items: center;
  justify-content: flex-start; */
  box-sizing: border-box;
  background-image:url('http://www.miaoxingjundd.com/loveb.png');
  background-repeat:no-repeat;
  background-size: 100% 100%;
  position: relative;
  font-family: 'xinghan';
} 

.mt10{
 margin-top: 10rpx; 
}

.linkword{
  color: #1890ff;
  text-decoration:underline;
  text-align: center;
  font-size: 16px;
  margin-bottom: 20px;
  position: absolute;
  top: 50%;
  margin: 0px 100px;
  z-index: 99999;
  /* margin-top:100rpx */
}
.shared-wrap{
  width: 100%;
  height: 100vh;
  background: #000000;
  opacity:0.5;
  position: absolute;
  z-index: 9999999;
  top:0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.shared{
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 9999999;
  top:0px;
  left: 0px;
  opacity:0.8
}

</style>