<template>
  <div class="container">
    <div class="title1">喵星君大大系统新用户注册</div>
    <div class="formClass">
      <van-form class="mt30">
         <div class="row-center-col-center mt30">
           <img src="../assets/yezi1.png" style="width:50px;height:16px" alt="">
           <div class="title2 ml5 mr5">性别</div>
           <img src="../assets/yezi2.png" style="width:50px;height:16px" alt="">
        </div>
        <van-field name="radio" style="margin:10px 0px">
          <template #input>
            <van-radio-group v-model="sex" direction="horizontal">
              <van-radio name="男">男生</van-radio>
              <van-radio name="女">女生</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <div class="row-center-col-center mt30">
           <img src="../assets/yezi1.png" style="width:50px;height:16px" alt="">
           <div class="title2 ml5 mr5">出生地</div>
           <img src="../assets/yezi2.png" style="width:50px;height:16px" alt="">
        </div>
        <van-field
          readonly
          clickable
          name="area"
          :value="provincecity"
          style="margin:10px 0px"
          @click="showArea = true"
        />
        <van-popup v-model="showArea" position="bottom">
          <van-area
            :area-list="areaList"
            :columns-num="2"
            @confirm="onConfirmCity"
            @cancel="showArea = false"
          />
        </van-popup>
        <div class="row-center-col-center mt30">
           <img src="../assets/yezi1.png" style="width:50px;height:16px" alt="">
           <div class="title2 ml5 mr5">出生时间</div>
           <img src="../assets/yezi2.png" style="width:50px;height:16px" alt="">
        </div>
        <van-field
          readonly
          clickable
          name="datetimePicker"
          :value="datetime"
          style="margin:10px 0px"
          @click="showPicker2 = true"
        />
        <van-popup v-model="showPicker2" position="bottom">
          <van-datetime-picker
            type="datetime"
            v-model="currentDate"
            title="选择完整时间"
            :min-date="minDate"
            :max-date="maxDate"
            @confirm="onConfirmTime"
            @cancel="showPicker2 = false"
          />
        </van-popup>
      </van-form>
    </div>
    <div class="center">
          <van-button :loading="loading" loading-text="请求中..." class="buttonclass mt30"  block type="info" native-type="submit" @click="onSubmit">注册</van-button>
    </div>
  </div>
</template>
<script>
import { areaList } from '@vant/area-data';
import { Toast } from 'vant';
import {postAction} from '@/api/manage'
export default {
  name: 'success',
  data() {
    return {
      username: '',
      sex:'',
      password: '',
      provincecity:'',
      showArea: false,
      areaList, // 数据格式见 Area 组件文档
      datetime: '',
      showPicker: false,
      showPicker2: false,
      minDate: new Date(1960, 0, 1),
      maxDate: new Date(2025, 10, 1),
      currentDate: new Date(1990, 0, 1),
      sex: '女',
      loading:false,
      userInfo:{}
   };
  },
  computed:{
    userinfoLove() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.$store.getters.getUserinfoLove || {};
    },
    openid() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.userinfoLove.jsopenid;
    },
    unionid() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.userinfoLove.unionid;
    },
    headimg() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.userinfoLove.image;
    },
    name() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.userinfoLove.nickname;
    },
    code(){
      return this.$route.query.code ? this.$route.query.code : ''
    },
  },
  mounted(){
    if(!this.unionid){
      if(!this.code){
        var url = encodeURIComponent(window.location.protocol+"//"+window.location.hostname+ '/loveThreeRegister')
        window.location.href=`https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxbb2af162829dc7fe&redirect_uri=${url}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`
      } else {
         this.getJsLogin()
      }
    }
  },
  methods:{
        getJsLogin(){
            const url = '/xcxlove/double/jslogin'
              postAction(url, {code:this.code}).then( res => {
                if(res.data.code == 200 || res.data.code == 400){
                    this.userInfo = res.data.msg
                    this.$store.commit('setUserInfoLove', this.userInfo)
                }
              })
        },
        onSubmit(){
            if(this.datetime == '' || this.provincecity == ''){
            Toast.fail('输入不能为空');
            return
            }
            let url = '/user/register'
            const city = this.provincecity.split('/')
            const dateList = this.datetime.split(' ')
            const params = {
            unionid: this.unionid,
            jsopenid: this.openid,
            image: this.headimg,
            nickname: this.name,
            sex: this.sex,
            province: city[0],
            city: city[1],
            date: dateList[0],
            time: dateList[1]
            }
            this.loading = true
            postAction(url,params)
            .then((res) => {
                if(res.data.code === 200){
                    Toast.success('注册成功');
                    this.userInfo = res.data.msg
                    this.$store.commit('setUserInfoLove', this.userInfo)
                    this.$router.push({ path: '/dailyluck'})
                }else{
                    Toast.fail(res.data.msg);
                }
            })
            .finally((res) => {
                this.loading = false
            })
        },
        onConfirmCity(values) {
            this.provincecity = values
            .filter((item) => !!item)
            .map((item) => item.name)
            .join('/');
            console.log('values',this.provincecity)
            this.showArea = false;
        },
        onConfirmTime(time) {
            console.log('values',time)
            this.datetime = this.parseTime(time,'{y}-{m}-{d} {h}:{i}' )
            console.log('values',this.datetime)
            this.showPicker2 = false;
        },
        parseTime (time, cFormat) {
            if (arguments.length === 0) {
            return null
            }
            if (!time) return ''
            const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
            let date
            if (typeof time === 'object') {
            date = time
            } else {
            if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
                time = parseInt(time)
            }
            if ((typeof time === 'number') && (time.toString().length === 10)) {
                time = time * 1000
            }
            date = new Date(time)
            }
            const formatObj = {
            y: date.getFullYear(),
            m: date.getMonth() + 1,
            d: date.getDate(),
            h: date.getHours(),
            i: date.getMinutes(),
            s: date.getSeconds(),
            a: date.getDay()
            }
            const timeStr = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
            let value = formatObj[key]
            // Note: getDay() returns 0 on Sunday
            if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
            if (result.length > 0 && value < 10) {
                value = '0' + value
            }
            return value || 0
            })
            return timeStr
        }
  }
}
</script>
<style scoped>
.container{
    width: 100%;
    height: 100vh;
    background-image: url('../assets/dailybg.png');
    background-size: 99% 100%;
    background-repeat: no-repeat;
    color: white;
}
.buttonclass{
  background: #FF3E42;
  font-size: 18px;
  border: 1px solid #FF3E42;
  border-radius: 0px  13px   0px  13px;
  width: 60%;
}
.title1{
  font-size: 24px;
  font-weight: bold;
  color: #1E1854;
  padding-top: 100px;
  margin: 0px auto;
  text-align: center;
}
.title2{
  font-size: 18px;
  font-weight: bold;
  color: #1E1854;
}
.formClass{
  width: 60%;
  margin: 0px auto;
  text-align: center;
  padding-top: 50px;
}
/deep/.van-field__label{
   width: 5.2em;
}
/deep/.van-cell{
  background: white;
  border-radius: 10px;
}
/deep/.van-field__label{
  color: #333;
}
/deep/.van-radio__label{
  color: #333;
}
/deep/ input{
  color: #333;
  background-color: transparent;
}
/deep/.van-field__control--custom {
   display: flex;
   justify-content: center;
}
.warningWord{
  text-align: right;
  color: rgb(46,211,120);
  font-size: 12px;
  padding-right: 10px;
}
.mt6{
  margin-top: 8px;
}
</style>


