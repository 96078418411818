<template>
    <div class="container">
        <div class="headercenter">
            <van-button @click="$router.go(-1)" plain round size="mini" icon="arrow-left" type="info" style="margin-right:20px"></van-button>
            <h2>录入断语</h2>
        </div>
        <el-form ref="dynamicValidateForm" :model="dynamicValidateForm" label-width="70px" class="demo-dynamic">
            <div v-for="(domain, index) in dynamicValidateForm.wordList" :key="index" class="formList">
                <el-form-item label="前后运"  :rules="[{ required:true, message: '输入不能为空'}]">
                    <el-select v-model="domain.type" class="width-24" clearable placeholder="请选择前后运" @change="selectParentList">
                        <el-option v-for="(item,i) in typeList" :key="`${i}type`" :label="item" :value="item" />
                    </el-select>
                </el-form-item>
                <el-form-item label="母类型"  :rules="[{ required:true, message: '输入不能为空'}]">
                    <el-select v-model="domain.parent" class="width-24" clearable placeholder="请选择母类型"  @change="selectSonList">
                        <el-option v-for="(item,i) in parentList" :key="`${i}par`" :label="item" :value="item" />
                    </el-select>
                </el-form-item>
                <el-form-item label="子类型" :rules="[{ required:true, message: '输入不能为空'}]">
                    <el-select v-model="domain.son" class="width-24" clearable placeholder="请选择子类型">
                        <el-option v-for="(item,i) in sonList" :key="`${i}son`" :label="item" :value="item"/>
                    </el-select>                
                </el-form-item>
                <el-form-item label="内容" :rules="[{ required:true, message: '输入不能为空'}]">
                <el-input
                    v-model="domain.content"
                    type="textarea"
                    :rows="6"
                    placeholder="请输入内容"
                />
                </el-form-item>
            </div>
            <div style="text-align:right; margin-top:10px">
            <el-button type="primary" icon="el-icon-plus" circle @click="addDomain" />
            <el-button v-if="dynamicValidateForm.wordList.length > 1" type="danger" icon="el-icon-minus" circle @click="removeDomain" />
            </div>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="input">录入断语</el-button>
        </div>
        <!-- <div class="mt20">
            <el-row>
               <el-col :span="7" class="ml5">
                    <el-select v-model="params.type" class="width-24" clearable placeholder="前后运"  @change="selectSonList">
                        <el-option v-for="(item,i) in typeList" :key="`${i}type`" :label="item" :value="item" />
                    </el-select>
               </el-col>
               <el-col :span="7" class="ml5">
                    <el-select v-model="params.parent" class="width-24" clearable placeholder="母类型"  @change="selectSonList">
                        <el-option v-for="(item,i) in parentList" :key="`${i}par`" :label="item" :value="item" />
                    </el-select>
               </el-col>
               <el-col :span="7" class="ml5">
                    <el-select v-model="params.son" class="width-24" clearable placeholder="子类型"  @change="selectSonList">
                        <el-option v-for="(item,i) in sonList" :key="`${i}par`" :label="item" :value="item" />
                    </el-select>
               </el-col>
              <el-button type="primary" @click="dataQuery">查询</el-button>
            </el-row>
        </div> -->
        <div class="mt10">
            <el-table
            style="margin-top:30px"
            v-loading="listLoading"
            :data="list"
            element-loading-text="Loading"
            border
            fit
            highlight-current-row
            :header-cell-style="{ background: '#f8f8f8' }"
            >
            <el-table-column align="center" label="ID" min-width="50">
                <template slot-scope="scope">
                <el-button type="text">{{scope.$index}}</el-button>
                </template>
            </el-table-column>
            <el-table-column label="前后运" min-width="80" align="center">
                <template slot-scope="scope">
                <span>{{ scope.row.type }}</span>
                </template>
            </el-table-column>
            <el-table-column label="母类型" min-width="80" align="center">
                <template slot-scope="scope">
                {{ scope.row.parent }}
                </template>
            </el-table-column>
            <el-table-column label="子类型" min-width="80" align="center">
                <template slot-scope="scope">
                {{ scope.row.son }}
                </template>
            </el-table-column>
            <el-table-column label="内容" min-width="300" align="center">
                <template slot-scope="scope">
                {{ scope.row.content }}
                </template>
            </el-table-column>
            <el-table-column label="操作" min-width="60" align="center">
                <template slot-scope="scope">
                  <el-button type="text" style="padding:0px" @click="handleDelete(scope.row.Id)">删除</el-button>
                </template>
            </el-table-column>
            </el-table>
        </div>
    </div>
</template>
<script>
import { postAction,getAction } from '@/api/manage'
export default {
  data () {
    return {
      dynamicValidateForm: {
        wordList: [{
          type: '',
          parent: '',
          son: '',
          content: ''
        }]
      },
      params:{
          type:'',
          parent:'',
          son:''
      },
      dictList:[{
          key:'',
          value:''
      }],
      typeList:[],
      parentList:[],
      parentListTemp:[],
      sonList:[],
      listLoading:false,
      list:[]
    }
  },
  created(){
      this.getDict()
      this.getAllList()
  },
  methods:{
    getDict(){
        const url = '/oa/getDict'
        postAction(url).then( res => {
		     if (res.data.code === 200) {
                this.dictList = res.data.msg
                this.typeList = this.dictList.map(item => item.tag)
                console.log('typeList',this.typeList)
		      } else {
                this.$message.error('字典获取失败')
		     }
          })
    },
    selectParentList(val){
      const tempList = this.dictList.find(item => item.tag === val).flag
      this.parentListTemp = tempList
      this.parentList = tempList.map(item => item.mum)
    },
    selectSonList(val){
      const tempList = this.parentListTemp.find(item => item.mum === val)
      this.sonList = tempList.son
    },
    input () {
      this.$refs.dynamicValidateForm.validate((valid) => {
        if (valid) {
        const url = '/oa/inword'
        postAction( url, this.dynamicValidateForm).then( res => {
		     if (res.data.code === 200) {
              this.dynamicValidateForm = {
                wordList: [{
                  type: '',
                  parent: '',
                  son: '',
                  content: ''
                }]
              },
              this.getAllList()
              this.$message.success(res.data.msg)
		      } else {
              this.$message.error(res.data.msg)
		     }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    dataQuery(){
    const url = '/oa/all_list'
    getAction(url).then( res => {
            if (res.data.code === 200) {
            this.list = res.data.msg.filter(item => item.del !== 1)
            } else {
            this.$message.error(res.data.msg)
            }
        })
    },
    getAllList(){
        const url = '/oa/all_list'
        getAction(url).then( res => {
		     if (res.data.code === 200) {
                this.list = res.data.msg.filter(item => item.del !== 1)
		      } else {
                this.$message.error(res.data.msg)
		     }
          })
    },
    handleDelete(Id){
      const url = '/oa/del_list'
          postAction(url, {Id: Id}).then( res => {
              if( res.data.code == 200) {
                this.$message.success(res.data.msg)
                this.getAllList()
              }
              else{
                this.$message.warning(res.data.msg)
              }
          })
    },
    removeDomain () {
      const length = this.dynamicValidateForm.wordList.length
      if (length > 1) {
        this.dynamicValidateForm.wordList.splice(length - 1, 1)
      }
    },
    addDomain () {
      this.dynamicValidateForm.wordList.push({
          type: '',
          parent: '',
          son: '',
          content: ''
      })
    },
  }
}
</script>
<style scoped>
.container{
  padding: 20px 30px;
}
.headercenter{
    display:flex;
    justify-content: center;
    align-items: center;
}
.time{
  display: flex;
  justify-content: space-between;
}
.line{
  display: flex;
  justify-content: center;
}
/deep/  .el-dialog__body{
  padding: 10px 30px;
}
/deep/  .el-form-item{
  margin-bottom: 17px;
}
.info-card{
	width: 80%;
	padding: 20px;
}
.formList{
   border: 1px solid #ddd;
   padding: 26px;
   margin-top: 6px;
}
.dialog-footer{
	text-align: center;
}
 /deep/ .el-tabs--border-card > .el-tabs__header .el-tabs__item:not(.is-disabled){
	color: #000;
 }
  /deep/ .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
    color: #1890ff;
}

</style>
