<template>
  <div style="width:100%;height:100vh" class="col-center">
    <div class="container">
      <!-- 增加注释 -->
      <img src="../assets/reliableHome.jpg" style="width:100%;height:100vh" alt="">
      <!-- <img src="../assets/botton.jpg" style="width:100px;height:40px" alt=""> -->
      <van-button class="generate2 buttonColor2" round block type="info" native-type="submit" @click="gotoDetail">开始测试</van-button>
    </div>
  </div>

</template>
<script>
import {postAction} from '@/api/manage'
export default {
  name: 'Login',
  data() {
    return {
      loading:false
    }
  },
  computed:{
    userinfoCareer() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.$store.getters.getUserinfoCareer || {};
    },
    openid() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.userinfoCareer.us_openid;
    },
    uid() {
      // 通过vuex的getters方法来获取state里面的数据
      return this.userinfoCareer.us_id;
    }
  },
  methods: {
     gotoDetail(){
      //  if(!this.openid || !this.uid){
      //    var url = encodeURIComponent(window.location.protocol+"//"+window.location.hostname+ '/reliableRegister')
      //     //window.location.href="https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxbb2af162829dc7fe&redirect_uri=http%3A%2F%2Fzy.miaoxingjundd.cn%2F&response_type=code&scope=snsapi_base&state=123#wechat_redirect"
      //       window.location.href=`https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxbb2af162829dc7fe&redirect_uri=${url}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`
      //  } else {
      //     this.$router.push({path:'/reliableRegister'})
      //  }
      this.$router.push({path:'/reliableRegister'})
     },
  }
}
</script>
<style scoped>
.container{
  position:relative;
  max-width: 400px;
  margin: 0 auto;
}
.generate2{
  position: absolute;
  bottom:20%;
  left: 22%;
  width: 220px;
  height: 50px;
  font-size: 18px;
  font-weight: 600;
}
.buttonColor2{
  background-image: url('../assets/botton.jpg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  color: #fff;
  border: 1px solid #fff;
}
</style>
